import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Player development, discipline fueled baseball's rise in early Graham years";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Player development, discipline fueled baseball&rsquo;s rise in early Graham years</h1>

                <div className="author-date">
                    <p id="author">Diego Palos Rodriguez</p>
                    <p id="date" className="gray">Senior Writer  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/story 1 Thresher Archives.jpeg")} />
                </div>
                <p className="caption gray">Rice baseball celebrates a victory during the 2002 season. Thresher archives.</p>

                <p>In the 79 years of Rice baseball prior to 1992, the Owls&rsquo; best year ended with a second-place finish in their former Southwest Conference. This, coupled with the lack of success seen in many other Rice sports at the time, did not dissuade incoming Athletic Director Bobby May from visions of national prominence.&nbsp;</p>
<p>David Hall was the first head baseball coach in Rice history with an overall winning record, but his sudden retirement in 1991 left May with the opportunity to build something bigger with the baseball program. According to May, the choice to hire Wayne Graham to lead the new era was not a difficult one.</p>
<p>&ldquo;It was pretty much a slam dunk for Wayne, [even when] you compare a couple of assistant coaches from really good programs with [him],&rdquo; May said. &ldquo;Wayne Graham won five national championships at the [junior college] level, was five times national coach of the year and at some point voted coach of this century in junior college, so he had incredible credentials.&rdquo;</p>
<p>Almost immediately after starting as head coach, Graham changed the environment within Rice baseball. He ran a tight ship that, according to former player and current head coach Jose Cruz Jr., was new to many players at the time.&nbsp;</p>
<p>&ldquo;I would say [the pace] was totally different [compared to] coach Hall,&rdquo; Cruz said. &ldquo;What Graham expected from all of us was something completely different to most of us that were there when [Hall] was. Just very demanding on effort, very demanding on toughness, understanding how much of a fight it was to go out there and play and compete.&rdquo;</p>
<p>According to Henry Zamudio, a longtime Rice baseball fan and the owner of Henry&rsquo;s Barber Shop, this transition was evident even from the stands.</p>
<p>&ldquo;He had the kids disciplined, you could tell they were scared of him,&rdquo; Zamudio said. &ldquo;One time I was there, I saw a pitcher that didn&rsquo;t cover first base [when he was supposed to]. He [had only] pitched maybe two or three balls in the game, and Wayne Graham went in and took him for another pitcher, real quick.&rdquo;</p>
<p>Chris Kolkhorst, a co-captain of the 2003 Owls, said Graham&rsquo;s expectations and order came from a desire to get the best out of his players.</p>
<p>&ldquo;[He was] amazing,&rdquo; Kolkhorst said. &ldquo;He was firm but fair. Once you realized that he only got on the guys he cared about and he was trying to make you better, then you understood him.&rdquo;&nbsp;</p>
<p>One of the first pieces that went into building up the Graham-era success was an increase in the quality of recruits that they brought in, many of whom hailed from the Greater Houston area. Cruz believes that the location and prestige of Rice boosted the program to unprecedented heights.&nbsp;</p>
<p>&ldquo;I think Houston, and Texas for that matter, are hotbeds for athletes, but especially for baseball,&rdquo; Cruz said. &ldquo;Especially around here, everybody understands what Rice is and how prestigious it is to be a part of Rice. If you&rsquo;re around town and you wanted to be any good, you wanted to come to Rice.&rdquo;</p>
<p>It was this time that saw many legendary Owls walk through campus and into professional baseball, including Cruz, Matt Anderson, Lance Berkman, Bubba Crosby, Damon Thames and many more. Through this change of culture and cultivation of talent, the Owls started to see success on the field.&nbsp;</p>
<p>Even with the constant improvement over the first few years of Graham&rsquo;s tenure, May said he believed there were still ways in which the team could have improved to reach their ultimate goal. Regional championships were progress, May said, but the ultimate goal remained national.</p>
<p>It only took Graham five years to bring the Owls to their first College World Series appearance in 1997. By the turn of the century, Graham&rsquo;s team won nearly every title they could and made it back to Omaha one more time.</p>
<p>At the same time, the Owls were in the midst of their first conference change since 1914, and a new stadium was under construction. Despite a completely different landscape on South Main, Zamudio said these were roaring times to be an Owls fan.</p>
<p>&ldquo;You could tell they were getting close and the fans were excited,&rdquo; Zamudio said. &ldquo;The ballpark was always full, especially when [we were in the] Regionals [and] the Super Regionals. It was hard to get tickets.&rdquo;</p>
<p>From Kolkhorst&rsquo;s perspective, it was this atmosphere that motivated the progress that the team was making.</p>
<p>&ldquo;It was awesome,&rdquo; Kolkhorst said. &ldquo;It was my first year at Rice, [we saw] some success and there was a crowd there, it was awesome. I just loved being a part of Rice baseball.&rdquo;</p>
<p>Zamudio, who took the trip to Omaha for Rice&rsquo;s third College World Series appearance in 2002, recalls that hundreds of Reckling regulars also followed the team to cheer on the Owls. Despite their shortcoming in Omaha that year, he believed that the team kept getting closer to national championship glory. They were getting &ldquo;better and better and better,&rdquo; he said.</p>
<p>All the pieces that went into the program since 1992 were coming together. Paul Janish, currently an associate head coach at Rice and an infielder for the Owls in 2003, said the experience in Omaha in 2002 gave the team the confidence they needed to get the best results.</p>
<p>&ldquo;It was so beneficial to go to Omaha my freshman year,&rdquo; Janish said. &ldquo;The following year &hellip; we go to Omaha not so much with the kind of starry eyes of, &lsquo;oh my gosh, we&rsquo;re in Omaha.&rsquo; It was more of the, &lsquo;This is where we&rsquo;re supposed to be.&rsquo;&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
