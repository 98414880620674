import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Twenty years after catching last out, Sinisi reflects on life in baseball";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Twenty years after catching last out, Sinisi reflects on life in baseball</h1>

                <div className="author-date">
                    <p id="author">Riya Misra</p>
                    <p id="date" className="gray">Features Editor  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/framed/sinisi.jpg")} />
                </div>
                <p className="caption gray">Vincent Sinisi swings. He was selected in the second round of the MLB draft, and retired after seven seasons in the minor leagues. Courtesy Rice Athletics.</p>

                <p>Somewhere inside Vincent Sinisi&rsquo;s home, a lone baseball sits in a case, on display. It&rsquo;s worn and still emblazoned with the College World Series logo, its appearance seemingly unaffected by the two decades since its last use. It&rsquo;s the same baseball that Sinisi caught to end the 2003 College World Series.</p>
<p>&ldquo;I got that ball, put it in my back pocket right after I caught it, got rid of all my other equipment, went to the dogpile after the game [and] gave the ball to coach [Wayne] Graham,&rdquo; Sinisi said. &ldquo;He held on to it for all these years. Last year, I actually went and saw him in Austin at his house, and he gave the ball back to me.&rdquo;</p>
<p>In the 20 years since Rice clinched the championship, Sinisi has both entered and retired from professional baseball. He was drafted in the second round by the Texas Rangers in 2003, and played in the minors for seven years in six different places in California, Texas and Oregon, before finally hanging up his glove.</p>
<p>&ldquo;Going into pro ball is a completely different atmosphere,&rdquo; Sinisi said. &ldquo;It&rsquo;s more of an every man for himself scenario, where you&rsquo;re trying to produce so you can advance to the next level and to the next level &hellip; It&rsquo;s hard to have a whole lot of chemistry with a team in pro ball versus a team in college that we spend every minute of every day with.&rdquo;</p>
<p>The shift in team camaraderie wasn&rsquo;t the only change between college and professional baseball &mdash; the life of a full-time athlete is not as lush as some may imagine, Sinisi says. He struggled to adjust to the rigors of the minors after playing at a well-funded program like Rice.</p>
<p>&ldquo;You're eating well, and then you go into pro ball and you're in some small town, California or small town wherever, staying at a motel, traveling on a bus for hours at a time, eating at Denny's instead of eating Saltgrass [Steak House],&rdquo; Sinisi said. &ldquo;When I was at Rice, we were flying everywhere, going to Hawaii once a year to play them. [We had] some pretty cool trips, and then you're playing in the armpits of America in some of these towns.&rdquo;</p>
<p>Sinisi retired from baseball in 2009, after his seven seasons on the field were largely marred by injuries. It was a sharp change for Sinisi, learning to extricate himself from a sport that had defined so much of his life from early childhood onwards.</p>
<p>&ldquo;There comes a time in sports where you're either damaged goods, or you're not good enough to keep up with some of the younger kids that are coming through. And that kind of caught up to me,&rdquo; Sinisi said. &ldquo;All I knew growing up was playing baseball. So, when the time comes to figure out what you're going to do next with your life, it's a big shock.&rdquo;</p>
<p>Fifteen years since formally picking up a bat, Sinisi has settled into a life that comfortably maintains its connections &mdash; albeit distant &mdash; to the baseball industry. Now a husband and father, he coaches his 13 year-old son and runs a vehicle transportation company that works with local businesses and baseball teams. Everything aside though, Sinisi isn&rsquo;t anticipating a resurgence in his baseball career anytime soon.</p>
<p>&ldquo;I can barely get out of bed in the morning. I'd hate to play baseball and pull a muscle or break something,&rdquo; Sinisi said. &ldquo;For me, the biggest joy is being able to teach [kids] my knowledge of the game and watch them learn it, respond to it and get better from it.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
