import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Rice after Roe: Abortion perspectives on campus";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Rice after Roe: abortion perspectives on campus</h1>

                <div className="author-date">
                    <p id="author">By Danika Li</p>
                    <p id="date" className="gray">Thresher Staff  |  April 12, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/abortion/1.JPEG")} />
                </div>
                <p className="caption gray">Courtesy Planned Parenthood Generation Action Rice Chapter</p>

                <p>June 24, 2022 was an awful day, Sarah Eleraky remembers.</p>
                <p>&ldquo;I felt a physical pain that day, I remember feeling heaviness on my chest,&rdquo; Eleraky, a Lovett College sophomore, said. &ldquo;It was more than just feeling like I&rsquo;m stuck in Texas and I can&rsquo;t get abortion if I need one, but also feeling like we&rsquo;re going backwards.&rdquo;</p>
                <p>On that day, the U.S. Supreme Court <a href="https://www.cnbc.com/2022/06/24/roe-v-wade-overturned-by-supreme-court-ending-federal-abortion-rights.html" target="_blank" rel="noopener">overturned</a> <em>Roe v. Wade</em>, the landmark case that established the constitutional right to abortion. The event was galvanizing for many Rice students involved with reproductive rights movements.</p>
                <p>One club on campus impacted by this decision was <a href="https://www.plannedparenthoodaction.org/communities/planned-parenthood-generation-action" target="_blank" rel="noopener">Planned Parenthood Generation Action</a>, a nationwide network of abortion-rights campus groups sponsored by Planned Parenthood. Allison Stocks started working to establish a Rice chapter for Planned Parenthood Generation Action in 2021.&nbsp;</p>
                <p>&ldquo;We hosted one event with pastries and parenthood &hellip; and I gave out buttons,&rdquo; Stocks said. &ldquo;But it was too late to become an official club, and so we started off with a slow start.&rdquo;</p>
                <p>In the wake of <em>Roe</em> being overturned, Stocks doubled down on her efforts. She said she raised over $450 via her Instagram story to purchase a steady supply of Plan B, created a program to have Planned Parenthood liaisons available to provide resources to each college and officially registered as a student club.</p>
                <p>For many students, the pro-choice movement is about more than just abortion, despite that being its most visible issue.</p>
                <p>&ldquo;Pro-choice, at its simplest, is a movement where people want to be respected,&rdquo; Stocks, a Lovett College junior, said. &ldquo;[Being] pro-choice is to strive to be a person who respects other human beings, including their autonomy but also their viewpoints and opinions.&rdquo;&nbsp;</p>
                <p>Talia Levy serves as a peer academic advisor and Rice Health Advisor as well as being a Planned Parenthood liaison. She said she considers being a liaison particularly impactful.</p>
                <p>&ldquo;I&rsquo;m appreciative that I can offer these resources because they feel a lot more real than the other resources that I offer,&rdquo; Levy, a Sid Richardson College sophomore, said. &ldquo;When somebody texts and asks, &lsquo;How do you access birth control? How do you figure out insurance and doctor&rsquo;s appointments? How do you get Plan B?&rsquo; it feels a lot more immediately important than telling somebody how to register for their classes or giving them cough drops.&rdquo;</p>
                <p>Almost all involved in the club are women. As the only male liaison, Thelonious Mercy,&nbsp; a Martel College sophomore, said he believes that the role of other people is vital in the conversation about abortion-rights and healthcare access.</p>
                <p>&ldquo;It&rsquo;s really important that we as people without uteruses take a more active role in helping provide those resources,&rdquo; Mercy said. &ldquo;So often, cis[gender] men have been in the position of power where they&rsquo;ve taken away those rights.&rdquo;</p>
                <p>Also impacted was Rice for Life, which was re-started last April, a few months before the consequential <em>Dobbs v. Jackson Women&rsquo;s Health Organization</em>. Jordan Killinger, a Will Rice College senior, serves as the club&rsquo;s current president, and Abigail Robert, a Jones College sophomore, serves as the event coordinator.</p>
                <p>Robert said she joined the club out of a desire to prevent &ldquo;vulnerable individuals from having their human dignity denied.&rdquo;</p>
                <p>&ldquo;[After] the overturning of <em>Roe v. Wade, </em>we felt emboldened to continue with even more fervor taking concrete steps toward helping women,&rdquo; Robert wrote in an email to the Thresher. &ldquo;Part of [that] includes providing women resources to be able to live healthy, flourishing lives before, during, and after their pregnancy.&rdquo;</p>
                <p>According to Robert, Rice for Life has undertaken activities such as collaborating with other Rice organizations on anti-human trafficking movements. Outside of Rice, Rice for Life volunteers with LifeHouse, a residential care facility for pregnant teens and young women.</p>
                
                <p><strong>What does it mean to live in Texas?</strong></p>
                
                <p>Texas has some of the most restrictive abortion laws in the nation, banning abortion in <a href="https://www.aclutx.org/en/know-your-rights/abortion-texas" target="_blank" rel="noopener">almost all cases</a>. Performing an abortion is now a <a href="https://www.texastribune.org/2022/08/25/texas-trigger-law-abortion/" target="_blank" rel="noopener">felony crime</a> with a minimum civil penalty of $100,000, and punishable by prison time ranging from <a href="https://www.npr.org/2022/08/27/1119795665/new-texas-trigger-law-makes-abortion-a-felony" target="_blank" rel="noopener">five years to life in prison</a>.&nbsp;</p>
                <p>Levy said her wariness of Texas as a <a href="https://news.gallup.com/poll/355034/texas-aligned-red-states-abortion.aspx" target="_blank" rel="noopener">majority anti-abortion state</a> means she is hesitant to display her involvement with Planned Parenthood outside of her residential college.</p>
                <p>&ldquo;I want to be a resource to students at my college, but I really am not interested in everybody on campus knowing my take,&rdquo; Levy said. &ldquo;That is less of a reflection of Rice and more a reflection of Texas.&rdquo;</p>
                <p>Both Rice for Life and PPGen@Rice said they strive to offer reproductive support on campus in different ways.</p>
                <p>According to Killinger, the purposes of Rice for Life are to equip the Christian community at Rice to respectfully discuss life-affirming principles with the broader community, as well as provide tangible resources to assist expectant mothers in a variety of situations.&nbsp;</p>
                <p>Despite the personal nature of reproductive care, Stocks said that no member of the Rice community is alone in facing its challenges.</p>
                <p>&ldquo;In cases where an individual might feel they have no choices or options, they should know that they should feel comfortable reaching out for support,&rdquo; Stocks said. &ldquo;This community has safe spaces where any individual can seek out judgment-free support for any issue.&rdquo;</p>

                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
