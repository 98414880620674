import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Years after career-ending injury, Jeff Niemann and baseball are friends again";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Years after career-ending injury, Jeff Niemann and baseball are friends again</h1>

                <div className="author-date">
                    <p id="author">Daniel Schrager</p>
                    <p id="date" className="gray">Sports Editor  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/framed/niemann.jpg")} />
                </div>
                <p className="caption gray">Jeff Niemann throws a pitch during his time at Rice. Niemann was drafted fourth overall by the Tampa Bay Devil Rays before an injury ended his baseball career. Courtesy Rice Athletics.</p>

                <p>There wasn&rsquo;t much fanfare the day Jeff Niemann was drafted by the Tampa Bay Devil Rays, even though the 6&rsquo;9&rdquo; pitcher was the fourth overall pick of the 2004 Major League Baseball draft.</p>
<p>&ldquo;The draft was the day after we lost to [Texas] A&amp;M [University] in our regional, so it was pretty bittersweet,&rdquo; Niemann said. &ldquo;I went up to Rice to listen to the draft. And I was at the baseball field, in our little room we had one computer in. I wasn&rsquo;t at my house, I wasn&rsquo;t with my parents. It was me and [fellow pitcher] Wade [Townsend] up at Rice listening to this 2004 early-days-of-internet [broadcast].&rdquo;</p>
<p>Townsend was taken four spots later, while another Rice pitcher, Philip Humber, one-upped Niemann, going third overall. According to Niemann, he had trouble believing that three teammates were taken in the first ten picks &mdash; a feat that has only occurred three times in league history.</p>
<p>&ldquo;[It was] lightning in a bottle, crazy things,&rdquo; Niemann said. &ldquo;You&rsquo;re living that moment in the present, at the same time being completely blown away by what&rsquo;s actually happening. It&rsquo;s real. But it&rsquo;s, &lsquo;There&rsquo;s no way this is real, right, guys?&rsquo; We came in [to Rice] three years ago, and turned it into this.&rdquo;</p>
<p>Niemann was dominant at Rice, especially in 2003, when he went 17-0 with an ERA of 1.70. But the minor leagues weren&rsquo;t as kind to Niemann as his time at Reckling Park had been.</p>
<p>&ldquo;I don&rsquo;t think there&rsquo;s any other industry that has a greater discrepancy from one level to the other one,&rdquo; Niemann said. &ldquo;Being a top pick on a team that was [a] 100 loss team when I was there early on, I was expecting it to move a little bit faster than it did. That led [to] some tough days.&rdquo;</p>
<p>Niemann did crack the majors, after three-plus seasons, and after a fourth, he made the Rays&rsquo; rotation. The former Owl finished fourth in the 2009 American League Rookie of the Year voting after posting a 3.94 ERA. While he let up more runs the following year, his strikeout and hits-allowed totals improved in every year of his MLB career. But Niemann said that just when he had adjusted to the MLB, injuries took his career away from him.</p>
<p>&ldquo;I had 92 starts in the big leagues, and I can honestly say that I actually, truly knew what I was doing for one of them,&rdquo; Niemann said. &ldquo;Then the next game, I broke my leg. And then the next time I pitched after that was when my shoulder went out.&rdquo;</p>
<p>At first, the 2003 national champion said he had trouble accepting that his rotator cuff and labrum injury would end his career.</p>
<p>&ldquo;We tried our damnedest for about a year and a half to rehab that shoulder and get it back to the point where I can go play again,&rdquo; Niemann said. &ldquo;You had your first [realization] where [you] say, &lsquo;I&rsquo;m still wearing a uniform, I&rsquo;m going to have to have surgery, and that sucks.&rsquo; I was released by the Rays, I&rsquo;m out there just as another guy. Then that realization is, wow, we can&rsquo;t do this at all anymore, period.&rdquo;</p>
<p>According to Niemann, one of the biggest challenges was fighting the urge to constantly work on his craft.</p>
<p>&ldquo;The first couple months, you had that overwhelming feeling of there&rsquo;s something I&rsquo;m supposed to be doing right now,&rdquo; Niemann said.</p>
<p>Niemann said he couldn&rsquo;t even watch baseball at first, but that he eventually decided to stop dwelling on his injury.</p>
<p>&ldquo;You can either use it as a crutch &hellip; or, you look at it like, &lsquo;Well, that was a hell of a situation we put ourselves in,&rsquo; and we move on to the next thing,&rdquo; Niemann said.</p>
<p>Niemann has since not only started watching baseball again, but has even tried his hand at coaching. He coaches high school baseball in Colorado, where he and his family moved early in the COVID-19 pandemic to spend more time in nature.&nbsp;</p>
<p>So far, Niemann said, his reunion with the sport he played for so long is off to a good start.</p>
<p>&ldquo;It took me about five years to get to that point of being open enough to see how it felt, to see where me and baseball were at that point in time,&rdquo; Niemann said. &ldquo;As it turns out, me and baseball, we&rsquo;re still friends.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
