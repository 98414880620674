import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "O-Week coords talk work, stress and pay";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">O-Week coords talk work, stress and pay</h1>

                <div className="author-date">
                    <p id="author">By Amber Hu and Jina Park</p>
                    <p id="date" className="gray">For the Thresher  |  Jan. 11, 2023</p>
                </div>

                <p id="author" className="gray">This article was updated Jan. 18 to correct a statement about student directors of the peer academic advising program. They are paid in a stipend, not at an hourly rate.</p>

                <p>For matriculating students, Orientation Week is their grand entrance into a new life full of possibilities and opportunities at Rice.&nbsp; Yet despite O-Week being recognized as one of the pinnacle Rice events, the student effort put into making O-Week happen often goes unappreciated and undercompensated, some coordinators say.</p>
<p>Coordinators bear a lot of responsibility in making O-Week happen, according to Dania Alqarawi, a former O-Week Coordinator from Lovett College.&nbsp;</p>
<p>&ldquo;The workload is intense,&rdquo; Alqarawi, a senior, said. &ldquo;I think people don&rsquo;t realize how many hours get put into what [O-Week] coords do. [During the summer], we would have five to ten hour meetings daily and stay up until 5 a.m. working to get things done.&rdquo;&nbsp;</p>
<div className='news-center-image'>
                    <img className='news-image' src={require("../../media/studentworkers/channing.jpeg")} />
                </div>

<p>Although past O-Week Coordinators may be available as possible resources, Alqarawi said that a great deal of O-Week Coordinators had to start their work from square one.&nbsp;</p>
<p>&ldquo;Some things, you could look at previous years [to get a feel for how it&rsquo;s done], like the O-Week book or Advisor applications, but you do have to redo a lot of the work or [try] from memory because there&rsquo;s no handbook,&rdquo; Alqarawi said. &ldquo;[For] the majority of [the work], like making merch or finding sponsorships, you have to start from scratch.&rdquo;&nbsp;</p>
<p>Some former Coordinators said their compensation was inadequate. Housing and boarding has long been provided for Coordinators over the summer, but it was only in <a href="https://www.ricethresher.org/article/2019/01/o-week-coordinators-now-eligible-for-need-based-summer-stipends" target="_blank" rel="noopener">2019</a> that the Dean of Undergraduates made need-based stipends up to $2,500 available. A new milestone was reached in 2020 when a stipend of $1,000 was distributed to all O-Week Coordinators, regardless of financial status.&nbsp;</p>
<p>However, according to Alqarawi, a calculation done by another Coordinator found that the $1,000 stipend amounted to less than a dollar per hour of work. Jazmine Castillo, president of the Questbridge Chapter at Rice and a former O-Week Coordinator, said that this posed a significant problem for first-generation low-income students.&nbsp;</p>
<p>&ldquo;I don&rsquo;t think I&rsquo;m in a particularly unique situation as an FGLI student in that I have bills to pay, [car payments and phone bills] to pay,&rdquo; Castillo, a McMurtry College senior, said. &ldquo;So even [though Rice] provided housing and dining on campus, it was still a question of [if I could] physically do this. I ended up making [the] decision to go forward with it, but [it] took a long time for me to figure out if it was worth it.&rdquo;&nbsp;</p>
<p>Some FGLI students who considered becoming a Coordinator decided to get an internship in their preferred field that paid more money, Castillo said.</p>
<p>&ldquo;[There are also] FGLI students who have to go home [to] help support their family during the summer [or] work [to] pay bills that weren&rsquo;t able to participate in O-Week due to this,&rdquo; Castillo added.&nbsp;</p>
<p>Araceli Lopez, the executive director of the Student Success Initiatives and First Year Programs at Rice, acts as the main point of communication between students and administration. She said that she believed the compensation O-Week Coordinators receive is fair.</p>
<p>Student Directors, who oversee the Coordinators, are also paid a flat stipend of $1,000. Maria Hancu, one of the Student Directors of the peer academic advising program, said that although helping to make O-Week work is a fulfilling experience, the amount of work being put in by O-Week Coordinators shouldn&rsquo;t go uncompensated.&nbsp;</p>
<p>&ldquo;[The issue isn&rsquo;t that] students [are] putting this much work into O-Week. That&rsquo;s what we want to do, because we want to contribute,&rdquo; Hancu, a McMurtry College junior, said. &ldquo;[The issue is that our work] is treated as [something] that&rsquo;s only good for us and not for [others] as well. The ideal is that people are doing a job that&rsquo;s personally fulfilling for them, [but] they&rsquo;re also contributing to others by doing the job. That should be reflected in [their] compensation.&rdquo;</p>

                <p id="author">Photo by Channing Wang. Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
