import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "'Two at a time': LifeHouse bridges the gap";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">'Two at a time': LifeHouse bridges the gap</h1>

                <div className="author-date">
                    <p id="author">By Morgan Gage</p>
                    <p id="date" className="gray">Editor-in-Chief  |  April 12, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/abortion/Pregnant woman_Vivian Lang.jpg")} />
                </div>
                <p className="caption gray">Vivian Lang / Thresher</p>

                <p>When <em>Roe v. Wade</em> was struck down in June 2022, the 23 abortion clinics across the state closed their doors to abortion patients. While some clinics such as Planned Parenthood branches continued to offer reproductive healthcare services, others <a href="https://www.texastribune.org/2022/07/26/abortion-texas-clinics-planned-parenthood/" target="_blank" rel="noopener">relocated</a> to &ldquo;haven&rdquo; states where abortion was still legal, such as New Mexico, or shut down entirely. As of October 2022, nearly <a href="https://www.houstonchronicle.com/politics/texas/article/Half-of-Texas-abortion-clinics-have-closed-since-17494334.php" target="_blank" rel="noopener">half of U.S. abortion clinic closures were in Texas</a>.</p>
                <p>This was the final blow in a <a href="https://www.texastribune.org/2021/12/10/texas-abortion-rights-supreme-court-history/" target="_blank" rel="noopener">long history</a> of restrictions on abortion in Texas, some of which were periodically <a href="https://www.texastribune.org/2016/06/27/us-supreme-court-rules-texas-abortion-case/" target="_blank" rel="noopener">struck down</a> or <a href="https://www.nytimes.com/2021/09/01/us/supreme-court-texas-abortion.html" target="_blank" rel="noopener">affirmed</a> by the Supreme Court in previous rulings. Abortion clinic numbers dwindled as a result.</p>
                <p>Simultaneously, the state legislature funneled funds into &ldquo;<a href="https://www.hhs.texas.gov/services/health/women-children/alternatives-abortion" target="_blank" rel="noopener">alternatives to abortions</a>,&rdquo; including counseling for employment readiness and parenting classes, care coordination for government services, assistance with the purchase of essentials such as formula and diapers and housing and support services through maternity homes.</p>
                <p>LifeHouse, though not a Texas Health and Human Services contracted provider, exists at the intersection of these services. Founded in 1988, their website claims they are &ldquo;only one of two maternity homes in a 14-county area open to minors as young as 12 years old.&rdquo; The Christian nonprofit is rooted in religious teachings, with the goal of setting up women facing unplanned pregnancies with a &ldquo;forever family centered on Christ&rdquo; by providing free lodging, meals, prenatal care, life skills training, counseling, access to work and school and &ldquo;God&rsquo;s overwhelming love.&rdquo;</p>
                <p>Claire Hao, executive director of LifeHouse, said that LifeHouse&rsquo;s mission is to care for women in crisis pregnancy &mdash; &ldquo;to give them hope, to give them a future&rdquo; &mdash; by providing support services as well as modeling parenting and life skills in a home-like setting, with &ldquo;house parents&rdquo; who live on the property alongside those they are supporting.</p>
                <p>&ldquo;We call it discipleship in the Christian world, which means, basically, that you&rsquo;re just teaching as you go. As you&rsquo;re living your life, others are living their life alongside, you are teaching that way of life,&rdquo; Hao said. &ldquo;We hope that our women just feel that love and support.&rdquo;</p>
                <p>According to Hao, LifeHouse, in the last year, added a full-time social worker as well as a full-time counselor to their staff in order to provide additional support, with the social worker &ldquo;looking at those larger systematic issues&rdquo; and counselor addressing &ldquo;deeper psychological stressors that they may have coming into the ministry.&rdquo;</p>
                <p>Gina &ldquo;G&rdquo; House, LifeHouse&rsquo;s director of development and donor relations, said that opportunities for learning such as parenting, cooking and healthy relationship classes are essential to the program&rsquo;s goal of setting up parents for long term success. She also hopes that people understand that supporting expecting families takes time.</p>
                <p>&ldquo;There are no quick fixes to these issues,&rdquo; House said. &ldquo;We are investing in life, lives, two at a time, we say. Invest[ing] in a life takes time and intentionality and perseverance and commitment and steadiness, and I just think that we have to give our women time to learn to &hellip; repair the broken things in their life, to be taught and trained with new, valuable things that they can use out in the community.&rdquo;</p>
                <p>With an ongoing debate between abortion-rights and anti-abortion advocates that, at times, frames anti-abortion advocates as calling for birth without providing resources, House says: &ldquo;The proof is in the history.&rdquo;</p>
                <p>&ldquo;LifeHouse didn&rsquo;t just start yesterday. There&rsquo;s so many decades of women that have come through, that have been supported. Sometimes they&rsquo;ll have their first child and sometimes their fourth depending on the situation, but LifeHouse has always been a safe haven,&rdquo; House said. &ldquo;I think of hope for women and will continue to be that. We stand behind what we say, we give our lives to this and the people that are staff at LifeHouse do as well.&rdquo;&nbsp;</p>
                <p>In order to better advocate for women, Hao said that the community needs to understand that for a woman to have a choice, she needs to have the opportunity to choose to parent or place a child up for adoption.</p>
                <p>&ldquo;We want to be here to say that it is wise for a woman to parent her baby or to put that baby up for adoption,&rdquo; Hao said. &ldquo;I&rsquo;m hoping that our city can get around that and understand that to be pro-woman means also to be pro-life, because we&rsquo;re allowing the woman a choice.&rdquo;</p>
                <p>Looking across the aisle, Hao said that there is a lack of pregnancy resources overall, whether someone chooses to receive an abortion or carry a pregnancy to term.</p>
                <p>&ldquo;Even if someone chooses an abortion, as a whole, as for our country, we are also not providing services for those women for healing, either,&rdquo; Hao said. &ldquo;We are fully pro-woman, we are fully pro-child, and that we are going to come along for the long haul next to this mom, encourage her [and] help her and her parenting journey.&rdquo;</p>
                <p>With a long list of applicants that continues to grow, LifeHouse has plans to expand. Hao said that LifeHouse expects to move to a more centralized location in Houston and move their postpartum services into apartments closer to bus lines to provide better access to employment opportunities in the city. Hao added they hope to partner with a childcare provider, increase capacity for those seeking their services, create more long-term availability and introduce additional opportunities for volunteers who want to contribute to LifeHouse&rsquo;s mission.&nbsp;</p>
                <p>&ldquo;It may seem often, in the media, that there aren't people &hellip; providing the services, but really, there's a whole army of people who are at the ready,&rdquo; Hao said. &ldquo;Our hope is in this next year that we can really mobilize that group that is saying we want to be here for these women for the long haul.&rdquo;</p>

                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
