import React, { useEffect, useState } from 'react';
import SideStory2 from '../../components/homepage/SideStory2';
import logo from "../../media/general/logo.png";
import date from 'date-and-time';
import ProjectStory from '../../components/homepage/ProjectStory';
import ProjectStoryReverse from '../../components/homepage/ProjectStory_reverse';

import white_houses from '../../media/ion/white_houses.jpg';
import ion_front from '../../media/ion/ion_front.jpg';
import racism from '../../media/ion/racism.jpg';
import shape from '../../media/ion/shape.jpg';
import street_art from '../../media/ion/street_art.jpg';

import mainImg from '../../media/ion/mainImg.jpg';
import newsImg from '../../media/ion/newsImg.jpg';
import featuresImg from '../../media/ion/featuresImg.jpg';
import artImg from '../../media/ion/artImg.jpg';
import dataImg from '../../media/ion/dataImg.jpg';

import abortionTimelineImage from '../../media/abortion/PlannedParenthood.png'
import abortionNewsImage from '../../media/abortion/jennifer_liu_vending_homepage.png'
import abortionProImage from '../../media/abortion/1.JPEG'
import abortionAntiImage from '../../media/abortion/Pregnant woman_Vivian Lang.jpg'
import abortionMainImage from '../../media/abortion/7-infographic home.jpeg'

import SWmainImg from '../../media/studentworkers/Hai-Van_Hoang_fgli_homepage.jpg';
import SWoweekImg from '../../media/studentworkers/channing.jpeg';
import SWcrisisImg from '../../media/studentworkers/katherine_hui copy.JPG';
import SWlaborImg from '../../media/studentworkers/Jennifer_Liu_job2 copy.jpeg';
import SWfgliImg from '../../media/studentworkers/fli cliff_ndidi nwosu copy.png';

import humber from '../../media/rice-baseball/framed/humber copy.jpg'
import janish from '../../media/rice-baseball/framed/janish copy.jpg'
import townsend from '../../media/rice-baseball/framed/townsend copy.jpg'
import sinisi from '../../media/rice-baseball/framed/sinisi copy.jpg'
import niemann from '../../media/rice-baseball/framed/niemann copy.jpg'
import baseballMain from '../../media/rice-baseball/main.jpg'
import baseballMain2 from '../../media/rice-baseball/baseball main 2.jpg'
import baseball1 from '../../media/rice-baseball/story 1 Thresher Archives.jpeg'
import baseball2 from '../../media/rice-baseball/story 2 Thresher Archives copy.jpeg'
import baseball4 from '../../media/rice-baseball/story 4 Screenshot of McKinsey report.png'
import baseball5 from '../../media/rice-baseball/story 5 Thresher archives.jpg'
import baseball6 from '../../media/rice-baseball/story 6 Thresher Archives.jpg'

export default function Homepage() {
  const picNum = 5
  const [headerHeight, setHeaderHeight] = useState("40vh")
  const [appear, setAppear] = useState(true)
  const [picState, setPicState] = useState()

  const current = new Date();
  const currentFormat = date.format(current, "dddd, MMMM DD YYYY")

  const [picScrollPos, setPicScrollPos] = useState(0)


  const picTimeOut = () => {
    return setInterval(() => {
      setPicScrollPos(prevPos => prevPos - 100)
    }, 5000)
  }

  useEffect(() => {
    if (picScrollPos <= (-1) * picNum * 100 || picScrollPos > 0) {
      setPicScrollPos(0)
    }
  }, [picScrollPos])


  useEffect(() => {
    console.log("appear watch")
    if (appear === true) {
      const picStateStart = picTimeOut()
      setPicState(picStateStart)
    } else {
      clearInterval(picState)
    }
  }, [appear])

  function triggerVideo() {
    setAppear(!appear)
    if (headerHeight == "40vh") {
      setHeaderHeight("95vh")
    } else {
      setHeaderHeight("40vh")
    }
  }
  return (
    <div>
      <div className='header-frame' style={{ height: `${headerHeight}`, transition: "height 1.5s ease" }}>
        <div className='ppt-pic-scroll d-flex bg-black'>
          <div className='ppt-pic-scroll-container d-flex' style={{ transform: `translateX(${picScrollPos}vw)`, transitionDuration: "2s" }}>
            <div className='pic-scroll-wrapper'>
              <img src={baseballMain} className='pic-scroll' />
            </div>baseballMain
            <div className='pic-scroll-wrapper'>
              <img src={baseball1} className='pic-scroll' />
            </div>
            <div className='pic-scroll-wrapper'>
              <img src={abortionTimelineImage} className='pic-scroll' />
            </div>
            <div className='pic-scroll-wrapper'>
              <img src={abortionNewsImage} className='pic-scroll' />
            </div>
            <div className='pic-scroll-wrapper'>
              <img src={ion_front} className='pic-scroll' />
            </div>
          </div>

        </div>

        <div style={{ opacity: `${appear ? "1" : "0"}`, transition: "opacity 1.5s ease" }}>
          <div className='ppt-pic-scroll-cover'></div>
        </div>

        <div className='position-relative text-white text-center p-4 roboto'>
          {
            appear ?
              <div>
                <div className='d-flex justify-content-center'>
                  <img src={logo} alt="The Rice Thresher" style={{ width: "38vw" }}></img>
                </div>
                <div className='mt-2'>RICE UNIVERSITY'S STUDENT NEWSPAPER — SINCE 1916</div>
                <div className='mb-2'>{currentFormat}</div>
              </div>
              :
              <></>

          }


          <div className='position-relative' style={{ zIndex: 99 }}>
            <i className={"bi fs-3 bi-chevron-double-" + `${appear ? "down" : "up chevron-up"}`} onClick={triggerVideo} style={{ cursor: "pointer", transitionProperty: "all", transitionDuration: "1s" }}></i>
            {/* {appear ? <div>Click to See Today's Top Pictures/Videos</div> : <></>} */}
          </div>

        </div>
        {
          appear ?
            <></>
            :
            <div className='header-picscroll-click-wrapper'>
              <div className='header-picscroll-click'>
                <i class="bi bi-caret-left-fill text-white fs-4" style={{ cursor: "pointer" }} onClick={() => { setPicScrollPos(picScrollPos + 100) }}></i>
                <i class="bi bi-caret-right-fill text-white fs-4" style={{ cursor: "pointer" }} onClick={() => { setPicScrollPos(picScrollPos - 100) }}></i>
              </div>
            </div>
        }
      </div>


      <div style={{ position: "relative", top: "2px", borderTop: "2px solid black" }} />
      <div className='body'>
        <div className='row body-content'>
          
          <div className='col-12 col-md-12 top-pick'>
            <hr />

            <ProjectStoryReverse projectPic={baseballMain} 
                          projectName="Reassessing 2003: what Rice&rsquo;s only national championship means 20 years later"
                          projectLink="/projects/baseball"
                          projectDesc1="Twenty years ago this June, Rice won its only national title in a team sport, the 2003 College Baseball World Series. In the 14 years that followed, the Owls made the NCAA regionals every season. But the six years since have seen the reign of long-time head coach Wayne Graham draw to a close and the short-lived tenure of his successor, Matt Bragga, come and go, as the Owls failed to qualify for even the conference tournament in Bragga's final year."
                          projectDesc2="The Thresher spoke to several members of the &lsquo;03 team, as well as coaches, staff and fans. The result is a detailed picture of the years leading up to &lsquo;03, the Owls&rsquo; fairytale season and how the program unraveled in the two decades since."
              storyPics={[
                <SideStory2 storyName="Player development, discipline fueled baseball's rise in early Graham years" storyLink="/projects/baseball/player-development-discipline-fueled-baseballs-rise-in-early-Graham-years" authorName = "Diego Palos Rodriguez" src={baseball1} />,
                <SideStory2 storyName="&lsquo;Consistent excellence&rsquo;: An oral history of baseball&rsquo;s 2003 30-game win streak" storyLink="/projects/baseball/consistent-excellence-an-oral-history-of-baseballs-2003-30-game-win-streak" authorName = "Chloe Singer" src={baseball2} />,
                <SideStory2 storyName="Rice considered structural changes to athletics in 2004 but stuck with status quo" storyLink="/projects/baseball/rice-considered-fundamental-changes-to-athletics-in-2004-but-stuck-with-status-quo" authorName = "Prayag Gordy" src={baseball4} />,
                <SideStory2 storyName="Wayne Graham looks back at final seasons, start of program's decline" storyLink="/projects/baseball/wayne-graham-looks-back-at-final-seasons-start-of-programs-decline" authorName = "Pavithr Goli" src={baseball5} />,
                <SideStory2 storyName="Bragga brought 'positive attitude,' priority shifts to his disappointing tenure at Rice" storyLink="/projects/baseball/bragga-brought-positive-attitude-priority-shifts-to-his-disappointing-tenure-at-rice" authorName = "Murtaza Kazmi" src={baseball6} />,
              ]} />
              <ProjectStoryReverse projectPic={baseballMain2} 
                          projectLink="/projects/baseball"
                          projectDesc1="Photos courtesy Tommy Lavergne."
              storyPics={[
                <SideStory2 storyName="Janish reflects on his unexpectedly long tenure with Rice" storyLink="/projects/baseball/janish-reflects-on-his-unexpectedly-long-tenure-with-rice" authorName = "Daniel Schrager" src={janish} />,
                <SideStory2 storyName="Philip Humber revisits a career of ups and downs" storyLink="/projects/baseball/philip-humber-revisits-a-career-of-ups-and-downs" authorName = "Landry Wood" src={humber} />,
                <SideStory2 storyName="'I have the ability to control time': Wade Townsend talks baseball, poker, his spiritual awakening" storyLink="/projects/baseball/i-have-the-ability-to-control-time-wade-townsend-talks-baseball-poker-his-spiritual-awakening" authorName = "Cadan Hanson" src={townsend} />,
                <SideStory2 storyName="Twenty years after catching last out, Sinisi reflects on life in baseball" storyLink="/projects/baseball/twenty-years-after-catching-last-out-sinisi-reflects-on-differences-between-college-minors" authorName = "Riya Misra" src={sinisi} />,
                <SideStory2 storyName="Years after career-ending injury, Jeff Niemann and baseball are friends again" storyLink="/projects/baseball/years-after-career-ending-injury-jeff-niemann-and-baseball-are-friends-again" authorName = "Daniel Schrager" src={niemann} />,
              ]} />

            <ProjectStory projectPic={abortionMainImage}
                          projectName="Abortion through the ages"
                          projectLink="/projects/abortion"
                          projectDesc1="The Supreme Court overturned Roe v. Wade in June, ending 50 years of constitutional protections for abortion in the United States. Abortion in Texas is now illegal in almost all cases."
                          projectDesc2="Students at Rice sought abortions before Roe, during its reign and will continue in the wake of Roe’s fall. The Thresher talked to alumni from the 1950s through the 2010s to build a timeline of relationships, sex and abortion at Rice."
              storyPics={[
                <SideStory2 storyName="A history of abortion, told through Rice alumni" storyLink="/projects/abortion/a-history-of-abortion-told-through-rice-alumni" authorName = "Prayag Gordy, Danika Li, Andrea Plascencia and Caroline Mascardo" src={abortionTimelineImage} />,
                <SideStory2 storyName="Rice addresses sexual health accessibility and campus recruitment after Dobbs" storyLink="/projects/abortion/rice-addresses-sexual-health-accessibility-and-campus-recruitment-after-dobbs" authorName = "Prayag Gordy" src={abortionNewsImage} />,
                <SideStory2 storyName="Rice after Roe: abortion perspectives on campus" storyLink="/projects/abortion/rice-after-roe-abortion-perspectives-on-campus" authorName = "Danika Li" src={abortionProImage} />,
                <SideStory2 storyName="'Two at a time': LifeHouse bridges the gap" storyLink="/projects/abortion/two-at-a-time-lifehouse-bridges-the-gap" authorName = "Morgan Gage" src={abortionAntiImage} />
              ]} />

            <ProjectStoryReverse projectPic={SWmainImg} 
                          projectName="Student workers discuss job conditions on campus"
                          projectLink="/projects/studentworkers"
                          projectDesc1="Student workers are everywhere on campus. Rice undergraduates run O-Week, play a role in academic advising, manage large residential college budgets, work for the athletic department and more. What conditions do these student workers face?"
              storyPics={[
                <SideStory2 storyName="Crisis and damage control: Rice student leaders uphold a culture of care" storyLink="/projects/studentworkers/crisis-and-damage-control-rice-student-leaders-uphold-a-culture-of-care" authorName = "Prayag Gordy and Ellie Cha" src={SWcrisisImg} />,
                <SideStory2 storyName="O-Week coords talk work, stress and pay" storyLink="/projects/studentworkers/o-week-coords-talk-work-stress-and-pay" authorName = "Amber Hu and Jina Park" src={SWoweekImg} />,
                <SideStory2 storyName="Labor movement at Rice has stalled, organizers say" storyLink="/projects/studentworkers/labor-movement-at-rice-has-stalled-organizers-say" authorName = "Prayag Gordy and Allison Yue" src={SWlaborImg} />,
              <SideStory2 storyName="FGLI student leaders tackle the hidden curriculum" storyLink="/projects/studentworkers/fgli-student-leaders-tackle-the-hidden-curriculum" authorName = "Sanjana Jain and Artie Throop" src={SWfgliImg} />,
              ]} />

            <ProjectStory projectPic={mainImg} 
                          projectName="The Ion: Rice’s innovation district joins Third Ward community"
                          projectLink="/projects/innovation"
                          projectDesc1="The Thresher is launching its Special Projects team with a thorough examination of Rice's Innovation District. As part of Rice's push beyond the hedges, the Innovation District is under construction in Third Ward, one of Houston's historically Black neighborhoods."
                          projectDesc2="We spoke with the Rice Management Company and with community members, who expressed both hopes for resources and fears of gentrification and displacement. We dove into the data, and we researched the history of Black art in Houston."
              storyPics={[
                <SideStory2 storyName="Rice’s Ion settles into Third Ward" storyLink="/projects/innovation/rices-ion-settles-into-third-ward" authorName = "Murtaza Kazmi and Matt Banschbach" src={newsImg} />,
                <SideStory2 storyName="Meet Rice's newest neighbors" storyLink="/projects/innovation/meet-rices-newest-neighbors" authorName = "Hajera Naveed, Riya Misra, Michelle Gachelin, Bonnie Zhao and Kathleen Ortiz" src={featuresImg} />,
                <SideStory2 storyName="Art and integration: Houston’s tradition of art challenging the paradigm" storyLink="/projects/innovation/meet-rices-newest-neighbors/art-and-integration-houstons-tradition-of-art-challenging-the-paradigm" authorName = "Joseph Flores" src={artImg} />,
              <SideStory2 storyName="The data is unclear on Ion’s gentrification impact" storyLink="/projects/innovation/the-data-are-unclear-on-ions-gentrification" authorName = "Victor Huang" src={dataImg} />,
              ]} />
          </div>
          <div className='d-flex justify-content-end'>
            <div className='me-1'>Project organization by </div> 
            <a href='mailto:prayaggordy@gmail.com'> Prayag Gordy</a>
            <p className='me-1'>, homepage design by</p>
            <a href='mailto:yh82@rice.edu'> Alexia Huang</a>
            <p className='me-1'>.</p>
          </div>
        </div>
      </div>
    </div>
  )
}