import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center"></h1>

                <div className="author-date">
                    <p id="author"></p>
                    <p id="date" className="gray">  |  April 19, 2023</p>
                </div>

                {/* <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/baseball/")} />
                </div> */}
                <p className="caption gray"></p>


                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
