import React, {useEffect, useState} from 'react';
import '../../stylings/AbortionTimeline.css'
import preRoe from '../../media/abortion/1-infographic.png';
import advertisement from '../../media/abortion/advertisement.png';
import PlannedParenthood from '../../media/abortion/PlannedParenthood.png';
import Wildenthal from '../../media/abortion/Wildenthal.jpg';
import Radhika from '../../media/abortion/Radhika.jpg';
import infographic from '../../media/abortion/7-infographic.png';

export default function AbortionTimeline() {

    React.useEffect(() => {
        document.title = "A history of abortion, told through Rice alumni";
    }, []);

    const [offset, setOffset] = useState(window.pageYOffset);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const stories  = [
        {
            "headline": "",
            "slug": "top",
            "byline": "",
            "content": 
            (<div>
                <p>In 1970, Mary* flew to Mexico for her abortion. The procedure was illegal in Texas — but she knew that without an abortion, her life would change drastically.</p>
                <p>In 1987, Lora Wildenthal, then a Rice graduate and now a Rice professor, was studying in Germany when she found out she was unexpectedly pregnant. She has children now, but did not want any at the time.</p>
                <p>In 2016, Radhika Sharma, then a Rice undergraduate, sought an abortion, but found campus silent — and silencing. “There are things we talk about all the fucking time,” she said, and abortion was not one of them.</p>
                <p>Scroll slowly through our timeline to learn about the history of abortion at Rice. We connect these personal stories with brief summaries of the years in between, told through our research and conversations with alumni.</p>
                <br />
                <p>If you have a story to share, reach out to <a href="mailto:thresher@rice.edu">thresher@rice.edu</a>.</p>
                <p className="caption gray">Design by Fadil Eledath</p>
            </div>)
        },
        {
            "headline": "Abortion before Roe",
            "slug": "abortion-before-roe",
            "byline": "Prayag Gordy",
            "content": 
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${preRoe})` }}></p>
                    <p className="caption gray">Number of references to "abortion" by year in print copies of the Thresher between 1950 and 1973, when <em>Roe v. Wade</em> was decided. Data courtesy University of North Texas Library from Thresher archives. Graphic by Robert Heeter.</p>
                    <p>When Rice&rsquo;s inaugural class of <a href="https://president.rice.edu/history-presidency" target="_blank" rel="noopener">48 men and 29 women</a> arrived in 1912, abortion was strictly illegal in Texas. <a href="https://guides.sll.texas.gov/abortion-laws/history-of-abortion-laws" target="_blank" rel="noopener">Texas&rsquo; first penal codes</a> outlawed almost all abortions as far back as the 1850s. Originally criminalizing &ldquo;[procuring] the miscarriage of any woman being with child,&rdquo; the laws were seldom updated over the following decades.</p>
                    <p>For much of the 20th century, abortion was not a hot topic on the pages of the Thresher. The first relevant mention of abortion came in the <a href="https://texashistory.unt.edu/ark:/67531/metapth230891/m1/4/?q=abortion" target="_blank" rel="noopener">Feb. 15, 1952 issue</a> in a story discussing the high death rates before 1650.</p>
                    <p>James Greenwood (&lsquo;58) said he was not aware of pre-marital sex among his classmates. Cody Greenwood, who also graduated in 1958 before marrying James two days later, said she could not remember any discussion of abortion.</p>
                    <p>&ldquo;To my knowledge in our class of 1958 there were no out of marriage pregnancies and no discussion or consideration of getting an abortion,&rdquo; Cody Greenwood wrote in an email to the Thresher.</p>
                    <p>Abortion made it onto the pages of the Thresher more frequently starting in the 1960s. <a href="https://texashistory.unt.edu/ark:/67531/metapth244893/m1/12/?q=abortion" target="_blank" rel="noopener">In 1963</a>, the Thresher advertised a lecture on &ldquo;Birth Control, Sterilization, and Abortion.&rdquo; <a href="https://texashistory.unt.edu/ark:/67531/metapth244969/m1/2/?q=abortion" target="_blank" rel="noopener">In 1966</a>, the paper printed a letter from a social critic about recognizing the &ldquo;existence&rdquo; of sex.</p>
                    <p>Later that same year, the Thresher wrote a front-page story titled, <a href="https://texashistory.unt.edu/ark:/67531/metapth244973/m1/1/?q=abortion" target="_blank" rel="noopener">&ldquo;Abortionist To Perform At Brown; Forum Sponsors Speaker At Rice.&rdquo;</a> This &ldquo;abortionist,&rdquo; as medical professionals who provided abortions were called at the time, claimed to have performed thousands of abortions where few were legal.</p>
                    <p>Also in 1966, <a href="https://intranet.bixbycenter.ucsf.edu/publications/files/HonoringSFsAbortionPioneers.pdf" target="_blank" rel="noopener">nine doctors were brought into court</a> in California, alleged to have provided abortions. Hundreds of doctors wrote in their defense, prompting California to slightly relax its restrictions on abortion. Within a few years, Alaska, Hawaii, New York and Washington state <a href="https://www.plannedparenthoodaction.org/issues/abortion/abortion-central-history-reproductive-health-care-america/historical-abortion-law-timeline-1850-today" target="_blank" rel="noopener">completely repealed</a> their bans on abortion, and others loosened their restrictions.</p>
                    <p>The National Organization to Legalize Abortion&rsquo;s referral service began its advertising campaign in the Thresher in the Nov. 19, 1970 issue.</p>
                    <p>&ldquo;PREGNANT? NEED HELP?&rdquo; the advertisement asked. &ldquo;The Abortion Referral Service will provide a quick and inexpensive end to your pregnancy.&rdquo;</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "'How could we go this far backward?' Anonymous, 1970",
            "slug": "how-could-we-go-this-far-backward",
            "byline": "Prayag Gordy and Caroline Mascardo",
            "content":
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${advertisement})` }}></p>
                    <p className="caption gray">An abortion referral agency printed multiple advertisements in the Thresher, including in the Nov. 19, 1970 issue. Courtesy Thresher archives.</p>
                    <p><em>Editor's note: This story contains explicit descriptions of abortion. An alumna interviewed was given the option of remaining anonymous in the interest of keeping their experiences private. The anonymous alumna was given a false name, which has been marked with an asterisk on first mention.</em></p>
                    <p>Mary* landed in Mexico and took a taxi to the hotel, where she saw many other young women.</p>
                    <p>&ldquo;You would see people and go, &lsquo;I wonder if they&rsquo;re here for the same reason,&rsquo;&rdquo; she said.</p>
                    <p>A few weeks earlier, Mary&rsquo;s doctor told her she was 14 weeks pregnant. In 1970, <a href="https://guides.sll.texas.gov/abortion-laws/history-of-abortion-laws" target="_blank" rel="noopener">abortion was illegal in Texas</a>, where Mary, then an underclassman at Rice, lived.</p>
                    <p>Mary was the first person in her family to go to college, <a href="https://www.heri.ucla.edu/PDFs/pubs/TFS/Special/Monographs/FirstInMyFamily.pdf" target="_blank" rel="noopener">typical of many of her classmates at the time</a>. Having a child, she feared, could send her home without a diploma.</p>
                    <p>&ldquo;I [asked], &lsquo;What can you do for me?&rsquo; and [my doctor answered], &lsquo;Nothing,&rsquo;&rdquo; Mary said. &ldquo;But he said, &lsquo;I swear there was an article in the newspaper recently about an organization who helps young women who are pregnant find access to abortion.&rsquo;&rdquo;</p>
                    <p>In the years before the 1973 landmark case <a href="https://www.oyez.org/cases/1971/70-18" target="_blank" rel="noopener"><em>Roe v. Wade</em></a>, which established constitutional protections for abortion, networks of <a href="https://www.washingtonpost.com/archive/lifestyle/1989/04/27/after-abortion-became-legal/fe71eb63-d547-47ed-9b35-1def815c7841/" target="_blank" rel="noopener">volunteers connected women</a> with doctors who performed abortions. Referral agencies pointed women to clinics in a handful of U.S. states where abortion was legal, as well as in Mexico, where on that fateful summer morning, Mary walked out of her hotel and into a car.</p>
                    <p>&ldquo;There were probably four to six of us in [the taxi],&rdquo; Mary said. &ldquo;Nobody said a word to each other, and they took us to the clinic.&rdquo;</p>
                    <p>The drive continued in silence.</p>
                    <p className='blockquote'>‘I literally was escaping’</p>
                    <p>Mary was born into instability. Her parents had each married a handful of times, and she did not meet her father until her teenage years. Mary&rsquo;s sister mothered a child with a man who physically abused her for decades, and Mary herself was a survivor of sexual abuse.</p>
                    <p>&ldquo;I literally was escaping a very different life, and that&rsquo;s what Rice represented to me,&rdquo; she said.</p>
                    <p>At home, Mary had received some reproductive health education, she said. But the pervasive culture deemed premarital sex as bad. &ldquo;Good girls didn&rsquo;t,&rdquo; Mary used to say, &ldquo;and good boys didn&rsquo;t pressure them to.&rdquo;</p>
                    <p>Rice was different.</p>
                    <p>&ldquo;It was very much of a free-love universe world,&rdquo; Mary said. &ldquo;Rice was way behind the East Coast, but it was still [that] everybody has the right to be a sexual being, and that this whole idea of monogamy is outdated.&rdquo;</p>
                    <p>There was so much opportunity and so little stigma on campus, according to Mary, that she and her boyfriend started having sex.</p>
                    <p>&ldquo;But this ethos is still in your head, which is, &lsquo;I shouldn&rsquo;t be doing this, I shouldn&rsquo;t be doing this, I shouldn&rsquo;t be doing this,&rsquo;&rdquo; Mary said.</p>
                    <p className='blockquote'>‘We thought we’d moved the needle’</p>
                    <p>A few hours after arriving at the clinic, Mary came out of anesthesia safe and physically healthy. In the immediate aftermath of her abortion, however, Mary said she entered a year-long period of turmoil &mdash; not because she believed abortion was immoral, but because she had been raised in an environment so vehemently opposed to pre-marital sex.</p>
                    <p>&ldquo;I couldn&rsquo;t yet come to grips with the guilt of what I had done,&rdquo; Mary said. &ldquo;I had done something that I had always believed was wrong &hellip; It was just a tremendous amount of self-criticism about being sexually active and not availing myself of protection.&rdquo;</p>
                    <p>Mary went on birth control a year later, which she said helped her begin to repair her relationship with sex.</p>
                    <p>It wasn&rsquo;t until Mary had children that she was able to fully see sex as a shameless act, she explained. Now, she believes it is important that young people have access to reproductive health information if they want to have sex.</p>
                    <p>&ldquo;Would [I] judge a young person for being sexually active?&rdquo; Mary asked. &ldquo;No. In fact, I very much want to say, &lsquo;Choose to do that under the right circumstances [and] be careful.&rsquo;&rdquo;</p>
                    <p>The years following <em>Roe</em> saw increases in women seeking legal abortions and a decline in its morbidity. Women also entered college and the workforce at record rates, in part attributable to the <a href="https://www.plannedparenthoodaction.org/blog/birth-control-became-legal-50-years-ago-and-here-are-our-5-favorite-things-about-it" target="_blank" rel="noopener">availability of birth control</a>.</p>
                    <p>&ldquo;All of us &lsquo;70s people look at the world today and go, &lsquo;This has to be a nightmare. How could we go this far backward?&rsquo;&rdquo; Mary said. &ldquo;There&rsquo;s so many arenas of life right now that I just cannot even imagine we&rsquo;re here. We thought we&rsquo;d done a lot of good things. We thought we&rsquo;d moved the needle.&rdquo;</p>
                    <p>Nevertheless, Mary remains committed to advocating for the right to abortion.&nbsp;</p>
                    <p>&ldquo;I am one avid supporter of every agency having to do with reproduction &hellip; because I believe it&rsquo;s so essential for women&rsquo;s futures to be able to make a conscious choice about this,&rdquo; Mary said. &ldquo;That&rsquo;s what [<em>Roe</em> has] meant to me for the last 50 years, and I&rsquo;m going to be committed to keeping this alive.&rdquo;</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "The decades after Roe",
            "slug": "the-decades-after-roe",
            "byline": "Prayag Gordy",
            "content":
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${PlannedParenthood})` }}></p>
                    <p className="caption gray">Anti-abortion protestors demonstrate outside of a Planned Parenthood clinic in Houston. Wildenthal obtained her birth control bills from this Planned Parenthood. Courtesy of Planned Parenthood of Houston & Southeast Texas Records, Special Collections, University of Houston Libraries.</p>
                    <p>The 1980s and 1990s saw record numbers of women seeking legal abortions. There were as many as 1.5 million legal abortions per year, according to the <a href="https://www.pewresearch.org/fact-tank/2022/06/24/what-the-data-says-about-abortion-in-the-u-s-2/" target="_blank" rel="noopener">Guttmacher Institute</a>, equivalent to 29.3 abortions per 1,000 women aged 15-44 at its peak.</p>
                    <p>The years following <em>Roe</em> were not without restrictions on abortion rights. In 1976, Henry J. Hyde, an anti-abortion congressman from the Chicago suburbs, introduced the <a href="https://www.aclu.org/other/access-denied-origins-hyde-amendment-and-other-restrictions-public-funding-abortion" target="_blank" rel="noopener">Hyde Amendment</a>, which prohibited federal Medicaid funds from being used for most abortions.</p>
                    <p>The Hyde Amendment particularly affected poor women and women of color, who are more likely to be on Medicaid &mdash; even though a disproportionate number of women who get abortions are low-income, young and women of color, according to the <a href="https://www.kff.org/womens-health-policy/issue-brief/the-hyde-amendment-and-coverage-for-abortion-services/" target="_blank" rel="noopener">Kaiser Family Foundation</a>.</p>
                    <p>&ldquo;The feminist concessions around this kind of thing led to the Hyde Amendment and poor women, women on welfare, being cut off from medical care,&rdquo; Helena Michie, the director of the Center for Women, Gender and Sexuality at Rice, said.</p>
                    <p>At the same time, Rice was transitioning away from women&rsquo;s residential colleges. In 1981, <a href="https://jones.rice.edu/beerbike-traditions" target="_blank" rel="noopener">Jones College became co-ed</a>, and <a href="https://www.browncollege.org/history-and-traditions" target="_blank" rel="noopener">Brown College followed</a> in 1987. Rice men then moved into the previously all-women colleges.</p>
                    <p>Kathy Behrens, a 1980 graduate who had moved from Jones College to the co-ed Will Rice College, said dating and sex were common in her time at Rice. She remembers the incoming class of freshmen women when she was an upperclassman at Will Rice.</p>
                    <p>&ldquo;They were cute. They were accomplished. They were sassy,&rdquo; Behrens said. &ldquo;They came in with strong personalities and ideas, and the guys just ate them up. I mean, it was like, &lsquo;oh my god.&rsquo;&rdquo;</p>
                    <p>Throughout the remainder of the &lsquo;80s and &lsquo;90s, anti-abortion activists continued to lobby against abortion access.</p>
                    <p>Internationally, President Ronald Reagan <a href="https://www.plannedparenthoodaction.org/communities/planned-parenthood-global/end-global-gag-rule" target="_blank" rel="noopener">introduced the 1984 Mexico City policy</a>, later known as a global gag rule. Under the global gag rule, any foreign organization that receives U.S. funding cannot provide any information about abortion, even with its own money.</p>
                    <p>Domestically, <a href="https://studentsforlife.org/learn/history-of-abortion/" target="_blank" rel="noopener">scores of legal cases</a> reached the Supreme Court. Some, like <em>Thornburgh v. American College of Obstetricians &amp; Gynecologists</em>, invalidated abortion restrictions; others, like <em>Webster v. Reproductive Health Services</em>, upheld bans on federal funds paying for abortions.</p>
                    <p>In Texas in 1985, <a href="https://www.texastribune.org/2021/09/21/texas-abortion-law-history/" target="_blank" rel="noopener">the legislature required</a> licensed physicians to perform abortions instead of nurse practitioners.</p>
                    <p>Anti-abortion activists continued to organize, marching on Washington, D.C., launching student groups and demonstrating outside of abortion clinics.</p>
                    <p>Anti-abortion extremists committed some <a href="https://pubmed.ncbi.nlm.nih.gov/1957842/" target="_blank" rel="noopener">110 acts of arsons and bombings</a> against abortion providers from 1977 to 1988. In 1993, an anti-abortion protestor <a href="https://www.nytimes.com/1993/03/11/us/doctor-is-slain-during-protest-over-abortions.html" target="_blank" rel="noopener">shot and killed a doctor</a> who provided abortions.</p>
                    <p>In 1992, the Supreme Court heard <a href="https://www.oyez.org/cases/1991/91-744" target="_blank" rel="noopener"><em>Planned Parenthood of Southeastern Pennsylvania v. Casey</em></a>, a case which considered requirements such as a 24-hour waiting period before an abortion. The Court upheld <em>Roe</em> while allowing restrictions on abortion that did not pose an &ldquo;undue burden.&rdquo; Anti-abortion state legislatures used the <em>Casey</em> decision to pass restrictions that <a href="https://studentsforlife.org/learn/history-of-abortion/" target="_blank" rel="noopener">Students for Life</a>, an anti-abortion group, says &ldquo;make up the incremental approach to reducing abortions.&rdquo;</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "The Golden Age. Lora Wildenthal, 1987",
            "slug": "the-golden-age",
            "byline": "Prayag Gordy and Andrea Plascencia",
            "content":
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${Wildenthal})` }}></p>
                    <p className="caption gray">Wildenthal, far left, as a student at Rice in 1983. Courtesy Lora Wildenthal.</p>
                    <p>Lora Wildenthal found herself in a sterile German hospital.</p>
                    <p>One woman in the ward, Wildenthal remembers, was a Turkish immigrant who had already birthed several children. She told the other women the doctors had sterilized her. It was unclear, Wildenthal said, whether she had a choice.</p>
                    <p>Another woman, possibly in her late 30s, lay in the bed next to Wildenthal&rsquo;s. She had spent weeks unable to decide the fate of her pregnancy. Finally, the doctors told her she was approaching the gestational limit for an abortion.</p>
                    <p>&ldquo;Don&rsquo;t you think these doctors and nurses are creepy?&rdquo; the woman asked Wildenthal. &ldquo;I mean, how can we come in here and kill these babies?&rdquo;</p>
                    <p>Wildenthal was furious. Wildenthal was there for her own abortion, in a German system that required a multiple-day hospital stay, with the procedure itself under general anesthetic &mdash; to scare women off, Wildenthal alleges.</p>
                    <p>Just a few months before, in 1987, Wildenthal graduated from Rice. Now, a few decades later, she is a professor in Rice&rsquo;s history department and a faculty associate in the Center for the Study of Women, Gender and Sexuality.</p>
                    <p>As a student at Rice, Wildenthal said she was thriving. She was happily sexually active, easily obtained her birth control from Planned Parenthood and even spent time living with her boyfriend.</p>
                    <p>&ldquo;This was Golden Age stuff,&rdquo; she said. &ldquo;I had access to whatever I needed access to, [and] I certainly would have had support systems for whatever had come up.&rdquo;</p>
                    <p>But in Germany, Wildenthal was afraid she would be stuck.&nbsp;</p>
                    <p>&ldquo;I thought my life would be over if I had to have that baby,&rdquo; Wildenthal said. &ldquo;I was all ready to go to graduate school, I knew that I didn&rsquo;t want a longer-term relationship with that very nice young man, it really felt like the end of life. One can say, &lsquo;Let&rsquo;s not be too dramatic. Is it really the end of my life?&rsquo; But it was not what I wanted to do.&rdquo;</p>
                    <p>Though she said she felt her boyfriend may have stayed, it was not a guarantee. As both the daughter and sister of single mothers, she was certain that life as a single mother did not fit into her future plans.</p>
                    <p>&ldquo;The last thing I wanted to do was be a single mother, not because I think there&rsquo;s anything immoral or wrong about it if that&rsquo;s what people want, but it didn&rsquo;t look like very much fun,&rdquo; Wildenthal said. &ldquo;From what I observe, it&rsquo;s a lot of poverty and a lot of struggle and a lot of hard work.&rdquo;</p>
                    <p>Forever the scholar, Wildenthal also worried she would have to end her education.</p>
                    <p>&ldquo;I saw how when my sister had her children so early, she was never really able to get away from home, and I was very fixated on just declaring my independence,&rdquo; Wildenthal said.</p>
                    <p>When Wildenthal managed to push through German bureaucracy and end up in that hospital ward &mdash; and receive the abortion she unquestionably wanted &mdash; she was relieved.</p>
                    <p>&ldquo;I was always relieved when I would realize [it&rsquo;s] been 10 years, it&rsquo;s been 20 years [since my abortion],&rdquo; Wildenthal said. &ldquo;It wasn&rsquo;t a frequent reflection, it was more like thank goodness that was available.&rdquo;</p>
                    <p>In fact, Wildenthal said she never intended on having children. That changed when she and her husband, also a professor, finally found jobs in the same city. The news shocked her family, she said.</p>
                    <p>&ldquo;If I were doing this against my will, this would be the worst thing in the world,&rdquo; Wildenthal added.</p>
                    <p>Among her areas of research, Wildenthal now studies gender and human rights. After Wildenthal finished her year in Germany and enrolled in graduate school, the study of women&rsquo;s and gender history was &ldquo;exploding,&rdquo; she said.</p>
                    <p>The literature she studied changed her perceptions of history &mdash; and of herself.</p>
                    <p>&ldquo;It&rsquo;s also fascinating to start reinterpreting your life, in terms of some of the analytical categories and problems that are set up in this literature,&rdquo; Wildenthal said.</p>
                    <p>Wildenthal said this motivated her to explore women&rsquo;s studies in her own teaching, and she has felt rewarded. Students, Wildenthal says, do not take women&rsquo;s studies courses &ldquo;absent-mindedly.&rdquo; Many, like Wildenthal, see reflections of their own lives.</p>
                    <p>The last few years have seen a shift from Wildenthal&rsquo;s &ldquo;Golden Age.&rdquo; In 2021, Texas passed a law, <a href="https://capitol.texas.gov/tlodocs/87R/billtext/pdf/SB00008F.pdf" target="_blank" rel="noopener">Senate Bill 8</a>, banning most abortions after six weeks. After the Supreme Court overturned <em>Roe v. Wade </em>earlier this year, almost all abortions are prohibited in Texas.</p>
                    <p>As her daughter graduated high school last year, Wildenthal said she wondered if those teenagers would have the same resources she did.</p>
                    <p>&ldquo;[I was] just looking at that commencement and thinking how many people standing up on stage right now are going to be caught up in this,&rdquo; Wildenthal said.</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "The turn of the century",
            "slug": "the-turn-of-the-century",
            "byline": "Prayag Gordy",
            "content":
                (<div>
                    <p>The turn of the century saw a ream of abortion restrictions passed in Teaxs and other states.</p>
                    <p>In 2003, the Texas legislature passed the Woman&rsquo;s Right to Know Act. According to the American Civil Liberties Union, the law required doctors to read <a href="https://www.aclutx.org/en/recent-history-restrictive-abortion-laws-texas" target="_blank" rel="noopener">&ldquo;misleading&rdquo; information</a> about abortion to patients 24 hours before the procedure. Two years later, Texas banned abortions after 24 weeks.</p>
                    <p>In 2011, Texas started to require doctors to show patients a <a href="https://www.verywellhealth.com/sonography-overview-4171740" target="_blank" rel="noopener">sonogram</a> with an audible fetal heartbeat. House Bill 2, passed in 2013, <a href="https://guides.sll.texas.gov/abortion-laws/history-of-abortion-laws" target="_blank" rel="noopener">further restricted abortion providers</a>, requiring facilities to meet the standards of ambulatory surgical centers and requiring doctors to have admitting privileges at a nearby hospital. Though the U.S. Supreme Court <a href="https://www.plannedparenthoodaction.org/issues/abortion/roe-v-wade/whole-womans-health-v-hellerstedt" target="_blank" rel="noopener">removed these provisions in 2016</a>, almost <a href="https://www.texastribune.org/2016/06/28/texas-abortion-clinics-have-closed-hb2-passed-2013/" target="_blank" rel="noopener">half of Texas&rsquo; abortion clinics had already been forced to close</a>, according to the Texas Tribune.</p>
                    <p>&ldquo;We&rsquo;re used to thinking of that sequence as incremental chipping away and incremental invention of new forms of intervention on the part of the opposition, to use the ideologies and language of protecting women in order to create functional barriers to care,&rdquo; Brian Riedel, the associate director of Rice&rsquo;s Center for Women, Gender and Sexuality, said.</p>
                    <p>As Texas continued to pass new restrictions, the courts continued to overturn them. In 2016, Texas tried to require clinics to pay a heightened fee to bury or cremate fetal tissue. In 2017, Texas tried to ban <a href="https://www.guttmacher.org/gpr/2017/02/de-abortion-bans-implications-banning-most-common-second-trimester-procedure" target="_blank" rel="noopener">dilation and evacuation abortions</a>, the most common second-trimester abortion method. Both bans created an &ldquo;undue burden&rdquo; on patients seeking abortions, the courts said.</p>
                    <p>At the same time, then-president Donald Trump was fulfilling his campaign promise to nominate anti-abortion justices to the Supreme Court. <a href="https://www.senate.gov/legislative/nominations/SupremeCourtNominations1789present.htm" target="_blank" rel="noopener">Neil Gorsuch, Brett Kavanaugh and Amy Coney Barrett</a> joined in 2017, 2018 and 2020, respectively, creating an anti-abortion majority.</p>
                    <p>In 2021, Texas passed the <a href="https://capitol.texas.gov/tlodocs/86R/billtext/pdf/HB01500I.pdf" target="_blank" rel="noopener">Senate Bill 8</a>, banning abortion after the detection of a fetal heartbeat, which usually occurs around six weeks &mdash; <a href="https://www.texastribune.org/2021/08/31/texas-abortion-law-supreme-court/" target="_blank" rel="noopener">before many people know</a> they are pregnant. Other states had tried to impose &ldquo;heartbeat bills,&rdquo; all of which the <a href="https://jamanetwork.com/journals/jama/article-abstract/2784582" target="_blank" rel="noopener">Supreme Court found in violation</a> of <em>Roe v. Wade</em>. However, the Court declined to hear the case due to its unique enforcement mechanism, allowing S.B. 8 to take effect.</p>
                    <p>S.B. 8 created and relied on a system of private enforcement. Citizens could sue to block abortions and to penalize anyone who &ldquo;aids and abets&rdquo; a patient in seeking one, <a href="https://www.aclutx.org/en/recent-history-restrictive-abortion-laws-texas" target="_blank" rel="noopener">according to the ACLU</a>.</p>
                    <p>Texas also passed a <a href="https://guides.sll.texas.gov/abortion-laws/trigger-laws" target="_blank" rel="noopener">&ldquo;trigger law&rdquo; in 2021</a> that would almost completely ban abortion 30 days after the Supreme Court overturned <em>Roe v. Wade</em>.</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "'Why aren't we talking about this?' Radhika Sharma, 2018",
            "slug": "why-arent-we-talking-about-this",
            "byline": "Danika Li, Andrea Plascencia",
            "content":
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${Radhika})` }}></p>
                    <p className="caption gray">Radhika Sharma pictured at Rice, where she graduated in 2018. Courtesy Radhika Sharma.</p>
                    <p>No two abortion stories are alike, but more importantly, no two abortion stories are spoken aloud on Rice campus.&nbsp;</p>
                    <p>During her four years at Rice, Radhika Sharma was a proud Brown College resident and a Rice Coffeehouse barista. She studied economics for job security and Asian studies for enjoyment, graduating in 2018. And during the spring of her sophomore year, she was faced with an unwelcome surprise: She was 10 weeks pregnant.</p>
                    <p>The second Sharma found out she was pregnant, she said she wanted to get an abortion.&nbsp;</p>
                    <p>Sharma did not face a moral dilemma, but she said she faced dilemmas in almost every other arena. She could not talk to her parents and did not feel comfortable approaching her magisters. She did not know how to explain to her male economics professor that she needed an extension. She felt no grief but did not understand why she could not stop crying. She did not know how to deal with an experience that no one wanted to talk about.</p>
                    <p>&ldquo;Nowhere did I see any literature, [any] resources, saying, &lsquo;Our students who want abortions or students who are having abortions, we have resources for you, we are here to support you,&rsquo;&rdquo; Sharma said. &ldquo;In fact, I felt completely the opposite.&rdquo;</p>
                    <p>In the weeks following her abortion, Sharma found herself stuck between feelings of shame, detachment and alienation, with no one to turn to outside of her close friends. Campus was both silent and silencing, she said, when it came to abortion.</p>
                    <p>&ldquo;I have never heard of a student having an abortion on campus, there&rsquo;s never a conversation I&rsquo;ve had that involves that,&rdquo; Sharma said. &ldquo;I found out almost at the end of my senior year about one other person who had one.&rdquo;</p>
                    <p>&ldquo;There are things we talk about all the fucking time,&rdquo; Sharma added. &ldquo;We talk about Beer Bike every other second, we talk about Baker 13 every other second, we talk about our tests, we talk about what&rsquo;s going on with administration, we talk about anything, we gossip all the time. Why aren&rsquo;t we talking about this thing that does affect so many students? This actual reproductive rights issue? What is it, what is it, what is it?&rdquo;</p>
                    <p>Unwanted or unviable pregnancy affects thousands across Texas. Around <a href="https://www.texastribune.org/2022/05/09/texas-abortions-by-the-numbers/" target="_blank" rel="noopener">50,000 to 55,000 Texans obtained legal abortions</a> each year from 2014 to 2021, with the majority being patients aged 20-29. In 2018, there were only <a href="https://rewirenewsgroup.com/2018/05/24/completely-intentional-fake-clinics-outnumber-abortion-providers-10-1-texas/" target="_blank" rel="noopener">12 licensed abortion clinics</a> in the entire state, down from 44 in 2014 and 62 in 2011.</p>
                    <p>Sharma acknowledged her privilege in her ability to make an appointment at the Houston Women&rsquo;s Clinic and afford the $600 cost. However, she said that nothing about the process of having an abortion was a privilege, referring to the experience as &ldquo;dehumanizing.&rdquo;</p>
                    <p>24 hours before the procedure, Sharma said she had to attend an appointment where clinicians showed her an ultrasound and questioned her repeatedly about her motivations.</p>
                    <p>&ldquo;The fact that by law, you&rsquo;re required to be guilted into having these emotions and into feeling shame and then have to wait 24 hours to have the procedure done is so extremely fucked up,&rdquo; she said.</p>
                    <p>Due to both trauma and strong drugs taken orally and vaginally, Sharma said she doesn&rsquo;t remember much of the procedure itself. However, she said she felt very alone during the appointment, despite the crowdedness of the clinic.</p>
                    <p>During the remainder of the spring semester, Sharma struggled deeply to process her experience, and reconcile the theoretical gestures and the silent realities of support she received from administration, structures and institutions at Rice.</p>
                    <p>&ldquo;I really want to be clear about how poorly resourced I felt by Rice,&rdquo; Sharma said. &ldquo;That has to be addressed.&rdquo;</p>
                    <p>A few weeks after the procedure, Sharma scheduled an appointment with the counseling center, not out of a sense of grief but because she felt like she ought to. She still hadn&rsquo;t processed the event and didn&rsquo;t understand how to begin.</p>
                    <p>When the day arrived, Sharma said she left her counseling appointment feeling awful. She did not discuss the abortion process itself with her counselor, but rather her physical symptoms in the weeks before she found out she was pregnant. The counselor undermined Sharma&rsquo;s symptoms. Sharma shouldn&rsquo;t have felt morning sickness at only 10 weeks &mdash; she would know because she&rsquo;d had two kids, the counselor said. Sharma&rsquo;s immediate reaction: &ldquo;What the fuck?&rdquo;</p>
                    <p>&ldquo;I was &hellip; met with someone who did not have any clue of how to actually make space for me and validate my experience,&rdquo; Sharma said. &ldquo;It definitely contributed to my shame, making me feel like it [was] so clear that Rice students don&rsquo;t get abortions and I [was] just alienated in this experience.&rdquo;</p>
                    <p>Looking back on her experience, Sharma said she is thankful she was able to rely on her partner and close friends to get through the remainder of a difficult semester.</p>
                    <p>&ldquo;If Rice knew how to support students when they&rsquo;re going through things like this, they would be able to show clear ways that they can make space for a student to be able to process this,&rdquo; Sharma said. &ldquo;I had all of my friends that helped me through this. It was not Rice creating a path for me to be able to know that I was cared for and safe.&rdquo;</p>
                    <br/><br/><br/>
                </div>)
        },
        {
            "headline": "Moving forward after Dobbs",
            "slug": "moving-forward-after-dobbs",
            "byline": "Prayag Gordy",
            "content":
                (<div>
                    <p className='lead-image' style={{ backgroundImage: `url(${infographic})` }}></p>
                    <p className="caption gray">The states surrounding Texas form a “patchwork” of abortion access, according to Brian Riedel, associate director of the Center for Women, Gender and Sexuality. Data courtesy New York Times. Graphic by Robert Heeter.</p>
                    <p>When the U.S. Supreme Court issued their ruling in <em>Dobbs v. Jackson Women&rsquo;s Health Organization</em> on June 24, <a href="https://www.oyez.org/cases/2021/19-1392" target="_blank" rel="noopener"><em>Roe v. Wade</em> was overturned</a>, and constitutional protections of abortion rights died in the United States, one year shy of the landmark case&rsquo;s 50th anniversary. Two months later, all abortions in Texas <a href="https://www.texastribune.org/2022/08/25/texas-trigger-law-abortion/" target="_blank" rel="noopener">are now illegal</a>, except to save the life of the mother.</p>
                    <p>Abortion providers can receive <a href="https://www.texastribune.org/2022/08/25/texas-trigger-law-abortion/" target="_blank" rel="noopener">life in prison</a> for performing an illegal abortion. The <a href="https://www.nytimes.com/interactive/2022/us/abortion-laws-roe-v-wade.html" target="_blank" rel="noopener">neighboring states</a> of Louisiana, Arkansas and Oklahoma have similar bans, though abortion remains legal in New Mexico.</p>
                    <p>&ldquo;There&rsquo;s going to be a patchwork connection,&rdquo; Brian Riedel, the associate director of the Center for Women, Gender and Sexuality at Rice, said. &ldquo;In Texas, with problems to the east [and north], the push is to go west, which for people in the west half of Texas is more achievable than for people in the east half of Texas.&rdquo;</p>
                    <p>Helena Michie, the director of the Center for Women, Gender and Sexuality, said it is not surprising that <em>Roe</em> was overturned.</p>
                    <p>&ldquo;It would be really easy to construct a kind of progressivist timeline where everything was getting better and better until &hellip; it all went away,&rdquo; Michie said. &ldquo;Well, abortion rights have been under assault since <em>Roe</em> was passed.&rdquo;</p>
                    <br/><br/>
                </div>)
        }
    ]

    const story_markers = [1850, 1940, 1967, 1973, 1987, 2000, 2016, 2022];

    const events = {
        1854: "Texas’ first penal codes outlaws almost all abortions",
        1925: "Texas re-codifies their abortion bans",
        1967: "Some states start to loosen their abortion restrictions",
        1970: "National Organization to Legalize Abortion’s referral service began its advertising campaign in the Thresher",
        1973: "Roe v. Wade is decided, offering constitutional protections for abortion",
        1974: "The first annual March for Life takes place in Washington, D.C.",
        1976: "The Hyde Amendment prohibits federal Medicaid funding being used for most abortions",
        1981: "Legal abortions peak at 29.3 abortions per 1,000 women aged 15-44",
        1984: "Ronald Reagan introduces the Mexico City policy, prohibiting foreign organizations that receive American funding from offering information about abortion",
        1985: "Texas requires licensed physicians to provide all abortions",
        1987: "Brown College is the last Rice residential college to become co-ed",
        1988: "American Collegians for Life, later renamed Students for Life of America, is founded",
        1991: "Clarence Thomas is confirmed as a Supreme Court justice",
        1992: "Planned Parenthood v. Casey is decided, upholding Roe while allowing restrictions on abortion that do not pose an “undue burden”",
        1993: "An anti-abortion protestor shoots and kills a doctor in Florida who provided abortions",
        1994: "Stephen Breyer is confirmed as a Supreme Court justice",
        2003: "Texas legislature passed the Woman's Right to Know Act, which required doctors to read “misleading” information about abortion to patients, according to the ACLU",
        2005: "Texas bans abortion after 24 weeks",
        2005: "John Roberts is confirmed as the Supreme Court’s chief justice",
        2006: "Samuel Alito is confirmed as a Supreme Court justice",
        2009: "Sonia Sotomayor is confirmed as a Supreme Court justice",
        2010: "Elena Kagan is confirmed as a Supreme Court justice",
        2011: "Texas requires doctors to show patients a sonogram with an audible fetal heartbeat",
        2013: "Texas requires facilities to meet the standards of ambulatory surgical centers and requires doctors to have admitting privileges at a nearby hospital",
        2016: "Though the Supreme Court overturns the 2013 provisions in Whole Woman's Health v. Hellerstedt, almost half of Texas abortion clinics had already closed",
        2016: "Texas requires clinics to pay a heightened fee to bury or cremate fetal tissue. A court issues an injunction",
        2017: "Texas tries to ban dilation and evacuation abortions, the most common second-trimester abortion method. A court issues an injunction",
        2017: "Neil Gorsuch is confirmed as a Supreme Court justice",
        2018: "Brett Kavanaugh is confirmed as a Supreme Court justice",
        2020: "Amy Coney Barrett is confirmed as a Supreme Court justice",
        2021: "Texas passes S.B. 8, banning most abortions after six weeks and allowing citizens to sue anyone who “aids and abets” a patient in seeking an abortion.\nTexas passes a “trigger law” that would ban abortion 30 days after the Supreme Court overturns Roe v. Wade",
        2022: "The Supreme Court overturns Roe v. Wade in Dobbs v. Jackson Women’s Health Organization.\nTexas’ trigger law goes into effect, banning almost all abortions in the state",
    }

    let timeline_years = []
    let year_class = {}

    for (let i = 1850; i <= 2022; i++) {
        let classes = [];
        if (i % 10 == 0) {
            classes.push("major");
        }
        if (i in events) {
            classes.push("notable");
        }
        if (story_markers.includes(i)) {
            classes.push("story-marker");
        }
        if (classes.length > 0) {
            classes.push("year-marker")
            timeline_years.push(i);
            year_class[`${i}`] = classes.join(" ");
        }
    };

    let story_years = [];
    let cur_group = [];
    for (const timeline_year of timeline_years) {
        if ((timeline_year != 1850) && story_markers.includes(timeline_year)) {
            story_years.push(cur_group);
            cur_group = [];
        }
        cur_group.push(timeline_year);
    }
    story_years.push(cur_group);

    return (
        <div id="abortion-timeline-container">
            <input id="expansion" defaultValue={false} type="checkbox" />
            <div className="container-xxl timeline">
                <div id='abortion-header' className={(offset > 100) ? "minimized col-8 offset-4" : undefined}>
                    <label className='expand' htmlFor='expansion'>
                        <span className="material-symbols-outlined small">expand_content</span>
                        <span className="material-symbols-outlined big">close</span>
                    </label>
                    A history of abortion, told through Rice alumni
                </div>
                {stories.map((cur_story, story_idx) => {
                    return (
                        <div className={`story-section row story-${story_idx}`} key={story_idx}>
                            <ol className='vertical-bar col-4'>
                                {story_years[story_idx].map(year => {
                                    return (
                                        <li key={year}>
                                            <span className={year_class[year]}>
                                                {year}
                                            </span>
                                            {
                                            (year in events) && 
                                            (<span className='event-info'>
                                                {events[year].split("\n").map((k, idx) => (<p key={idx}>{k}</p>))}
                                            </span>)
                                            }
                                        </li>
                                    )
                                })}
                            </ol>
                            <div className='story-container col-8'>
                                <a href={`#${cur_story.slug}`} className='story-headline' id={cur_story.slug}>
                                        {cur_story.headline}
                                    {/* </div> */}
                                </a>
                                <div className='story-byline'>{cur_story.byline}</div>
                                <div className='story-content'>
                                    {cur_story.content}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
} 