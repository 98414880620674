import React from 'react'

export default function SWHomePage() {
  React.useEffect(() => {
    document.title = "Student workers discuss job conditions on campus";
}, []);

  return (
    <div className='row justify-content-center p-0 bg-dark'>
        <div style={{padding: 0, width: "100%", height: "80vh", position: "absolute", top: 0, left: 0}} className="studenthomebg"></div>
        <div style={{padding: 0, width: "100%", height: "80vh", position: "absolute", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.8)"}}></div>
        <div className='col-12 col-md-7 p-5 position-relative pb-3 pb-md-5'>
            <h1 className='student-workers-title oswald'> STUDENT <br></br> WORKERS </h1>
        </div>
        <div className='col-12 col-md-4 p-5 position-relative pt-0 pt-md-5'>
            <p className='text-grey2 roboto'>Student workers are everywhere on campus. Rice undergraduates run O-Week, play a role in academic advising, manage large residential college budgets, work for the athletic department and more. What conditions do these student workers face?</p>
            <div className='oswald text-grey1' style={{textAlign: "right"}}> Designed by Alexia Huang </div>
            <div className='oswald text-grey1' style={{textAlign: "right"}}> Illustration by Hai-Van Hoang </div>
        </div>
        <div className='row p-0 roboto text-light position-relative' style={{minHeight: "55vh", height: "fit-content", width: "100%", backgroundColor: "#000"}}>
        <div className='col-6 col-md-3 p-5 text-grey2' style={{backgroundColor: "rgba(0,0,0,1)"}}>
              <div className='student-hover'>
                <h4 className='fw-bold' style={{color: "rgb(203, 93, 234)"}}><a href="/projects/studentworkers/crisis-and-damage-control-rice-student-leaders-uphold-a-culture-of-care" style={{color: "rgb(189, 88, 217)"}}>Crisis and damage control: Rice student leaders uphold a culture of care</a></h4>
                <div className="lora my-3">
                "Student leaders say they appreciate the opportunity to shape their community — but when something goes wrong, who is left in charge?"
                </div>
                <div style={{textAlign: "right"}} className="text-grey3 oswald">
                  by Prayag Gordy and Ellie Cha
                </div>
              </div>
            </div>
            
            <div className='col-6 col-md-3 p-5 text-grey1' style={{backgroundColor: "rgb(20, 20, 20)"}}>
              <div className='student-hover'>
                <h4 className='fw-bold' style={{color: "rgb(189, 88, 217)"}}><a href="/projects/studentworkers/o-week-coords-talk-work-stress-and-pay" style={{color: "rgb(189, 88, 217)"}}>O-Week coords talk work, stress and pay</a></h4>
                <div className="lora my-3">
                "Despite O-Week being recognized as one of the pinnacle Rice events, the student effort put into making O-Week happen often goes unappreciated and undercompensated," some coordinators say.
                </div>
                <div style={{textAlign: "right"}} className="text-grey3 oswald">
                  by Amber Hu and Jina Park
                </div>
              </div>
            </div>

            <div className='col-6 col-md-3 p-5 text-grey2' style={{backgroundColor: "rgb(40, 40, 40)"}}>
              <div className='student-hover'>
                <h4 className='fw-bold' style={{color: "rgb(223, 108, 255)"}}><a href="/projects/studentworkers/labor-movement-at-rice-has-stalled-organizers-say" style={{color: "rgb(189, 88, 217)"}}>Labor movement at Rice has stalled, organizers say</a></h4>
                <div className="lora my-3">
                "Students across the country are negotiating for more manageable working conditions. But at Rice, student efforts to organize have fallen short."
                </div>
                <div style={{textAlign: "right"}} className="text-grey3 oswald">
                  by Prayag Gordy and Allison Yue
                </div>
              </div>
            </div>

            <div className='col-6 col-md-3 p-5 text-grey1' style={{backgroundColor: "rgb(60, 60, 60)"}}>
              <div className='student-hover'>
                <h4 className='fw-bold' style={{color: "rgb(196, 91, 255)"}}><a href="/projects/studentworkers/fgli-student-leaders-tackle-the-hidden-curriculum" style={{color: "rgb(189, 88, 217)"}}>FGLI student leaders tackle the hidden curriculum</a></h4>
                <div className="lora my-3">
                "Many first-generation low income students have struggled to balance their many commitments, from courses and social activities to self-advocacy and finding income."
                </div>
                <div style={{textAlign: "right"}} className="text-grey3 oswald">
                by Sanjana Jain and Artie Throop
                </div>
              </div>
            </div>
        </div>
      </div>
  )
}
