import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "&lsquo;Consistent excellence&rsquo;: An oral history of baseball&rsquo;s 2003 30-game win streak";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">&lsquo;Consistent excellence&rsquo;: An oral history of baseball&rsquo;s 2003 30-game win streak</h1>

                <div className="author-date">
                    <p id="author">Chloe Singer</p>
                    <p id="date" className="gray">Thresher Staff  |  April 19, 2023</p>
                </div>

                {/* <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/")} />
                </div>
                <p className="caption gray"></p> */}

<p>20 years ago, Rice came within four games of the longest winning streak in college baseball history. On their way to their first national title, the Owls rattled off 30 wins in a row. The streak lasted from Feb. 18 to April 9, garnered national attention and catapulted Rice to a 33-1 record and the No. 1 spot in the national poll.</p>
<p>The Thresher spoke to several players and staff from that team, who told the story behind the streak to Omaha.</p>
<p><em>Rice ranked No. 4 in the 2003 preseason poll after going 54-12 season the season before and making a trip to Omaha for the College Baseball World Series. However, they had an early exit from the CBWS after losing back to back games against the University of Texas at Austin and Notre Dame University.&nbsp;</em></p>
<p><strong>Greg Mitchell, Rice baseball student manager from 2000-2004:</strong> Going into the &rsquo;03 season, there was definitely a sense of [or expectation] to win. For me, in 2002, it was very exciting to be at the College World Series for the first time, and it was surprising for everybody that didn&rsquo;t go beyond those two games &hellip; We wanted to make sure that the next season we got farther than that.&nbsp;</p>
<p><em>With most of the team able to return for the 2003 season, Rice was poised for success.&nbsp;</em></p>
<p style={{textAlign: 'center'}}><strong>Part 1: A losing start</strong></p>
<p><strong>Jonathan Yardley, play-by-play broadcaster from 2002-2005:</strong> Omaha was the expectation. I don&rsquo;t think that&rsquo;s overstating it. We had lost one starter, one reliever and maybe two guys from the lineup. We brought in Josh Baker as a transfer to fill the spot in the rotation. We had guys come in the lineup who seemed ready to play right away. I think it was a very confident group.</p>
<p>The beginning of the year, you&rsquo;re trying to establish what your pitching is going to be and how you&rsquo;re going to use it. I think the lineup was pretty well set &hellip; The big issue was our best pitcher Stephen Hurst was hurt. He&rsquo;s the guy who went 13-3 with a 2.79 ERA the year before.</p>
<p>Josh Baker just transferred in from [The University of] Alabama and [he] is Lance Berkman&rsquo;s brother in law. Rice is home for him. His brother, Ryan Baker, had played at Rice &hellip; Obviously, we lose the Alabama game. He&rsquo;s knocked out in the fourth inning. It&rsquo;s not the smoothest transition. At that point, to me, the question was how is everybody going to do pitching wise. How was [Jeff] Neimann going to do pitching on the weekend, because he had been the midweek starter the year before? How was Baker going to do? Can he be the Sunday guy? And then does that leave Wade [Townsend] as the midweek guy? Who was the midweek guy? We didn&rsquo;t know.&nbsp;</p>
<p><em>The first game of the winning streak was against the University of Houston on a Tuesday night. The game took place on the road at Cougar Fieldhouse against a top-25 opponent and crosstown rival.&nbsp;</em></p>
<p style={{textAlign: 'center'}}><strong>Part 2: Streaking without the shaving cream</strong></p>
<p><strong>Jonathan Yardley:</strong> Then we start Silver Glove play with Houston. And I think Wade really announced himself.&nbsp;</p>
<p><strong>Wade Townsend, pitcher from 2002-2004: </strong>&nbsp;There were only two people that thought I was going to have a really good year that year, and that was me and [head] coach [Wayne] Graham. He gave me every chance to make sure [it happened]. I started off terribly that year, like the first two outings, and then he got me pointed in the right direction and just kind of snowballed. He gave me one start against Houston, and that went well.&nbsp;</p>
<p><strong>Jonathan Yardley:</strong> He pitched six shutout innings.&nbsp;</p>
<p><strong>Wade Townsend:</strong> Then I pitched the next Tuesday and it was like, well, this is going well, let&rsquo;s just keep this like it is.&nbsp;</p>
<p><strong>Jonathan Yardley:</strong> We crushed the Big 12 [Conference], and Wade personally could have been Big 12 pitcher of the year for the number of times he beat a Big 12 team and dominated them.</p>
<p><strong>Wade Townsend: </strong>&nbsp;I prefer pitching in those [big] games, because I&rsquo;m an adrenaline freak, and I feed off the crowd. I think Graham kind of knew that.</p>
<p><strong>Greg Mitchell:</strong> Coach Graham, even before the 2003 season, was an extremely successful coach. The players he had coached had been very successful in college and very successful in the Major Leagues. I think there was a great respect for that and so people were definitely listening to him.&nbsp;</p>
<p><strong>Wade Townsend:</strong> If somebody is talented at what they do, understands how to think and how to teach that, then can teach anything. He could have been a great coach or a great teacher and literally anything.&nbsp;</p>
<p><strong>Greg Mitchell:</strong> Coach Graham [thought] about things in a much more global [way] than just baseball. He would take examples from history, especially Texas history. He would come up with things that were, for college aged students, a little bit out there. You knew it was wisdom, but he didn&rsquo;t quite know enough. That alone was enough to give him a sense of authority.</p>
<p><strong>Wade Townsend:</strong> I consider [Coach Graham] more of a Zen Buddhist monk than a baseball coach.&nbsp;</p>
<p><em>The streak had reached 12 games, but the Owls&rsquo; next game was versus Texas, the defending national champions and the team that knocked them out of the CBWS the previous year. With the streak and CBWS seeding on the line, the team was determined to get a win.</em></p>
<p style={{textAlign: 'center'}}><strong>Part 3: When Goliath came to town</strong></p>
<p><strong>Jonathan Yardley:</strong> The Texas game was everything you want the college baseball regular season in Texas to be &hellip; Texas, in the year before, had beaten us three times, twice by one run and once by two runs.&nbsp;</p>
<p><strong>Greg Mitchell:</strong> When Texas came to Reckling Park, it was like an army. I don't know if there are two buses, but it feels like there's two or three buses. I'm the [one Rice] student manager, it felt like they had five student managers or more.&nbsp;</p>
<p><strong>Paul Janish, shortstop from 2002-2004:</strong> It's kind of like a David and Goliath thing if you really look at how resourced and big that university is versus Rice. We embraced that and liked it. Beating them, for us, was substantial.&nbsp;</p>
<p><strong>Jonathan Yardley:</strong> When we announced the Texas game, [there were] 4,525 [people at the game], it was packed, and it was awesome.&nbsp;</p>
<p><strong>Kim Koehn, director of baseball operations from 2000-2008:</strong> It had a playoff atmosphere in the middle of March.&nbsp;</p>
<p><strong>Wade Townsend:</strong>&nbsp; It was the best game of college baseball I've ever seen. Texas had a good team and so did we. A lot of people compared [the rivalry] to Duke [University] [and the University of] North Carolina [at Chapel Hill]. We knew all of them, because we played with them in the summer. We grew up playing against them. We didn't like them, and they didn't like us because we talked &hellip; We were talking the whole time, before the game, after the game, during the game, [we] never stopped.</p>
<p>Before the game, Graham comes to me and he tells me, &ldquo;I want you to throw every fastball on the inside corner as hard as you can. If you miss, break their fucking elbow.&rdquo; That was his only instruction and that was to get me totally out of any fear mindset, so I could just go up there and throw the hell out of the ball.&nbsp;</p>
<p>I think I walked one but that was a bullshit call, and they scored one off me somehow. In the top of the eighth, they have guys on second and third with one out and their one-two hitters [coming up]. One was Tim Moss, who was a third round draft pick, and Coach Graham doesn't go to the bullpen. He sticks with me and I'm on an adrenaline high.&nbsp;</p>
<p>At this point, I know this is my last inning, so I'm literally throwing as hard as I can. I strike out Tim Moss, which is the biggest one, because now you can get a pop up or ground ball and still get out of it. Then, Omar Quintanilla, a great player, first round draft pick who played in the major leagues for four or five years, is up next. The one pitch you don't want to throw to that guy is a fastball away because he's great at just taking the ball and slapping it to left. I've only been throwing him [pitches on the inner part of the plate] the whole time. I throw him two fastballs as hard as I can, and he fouls off both of them. I told the catcher, &ldquo;Get up on the outside corner, I'm throwing as hard as I can.&rdquo; I threw a pitch 98 miles per hour, which is my hardest pitch of the night. And it was just on the black and I threw it by him so I'm going nuts. Everybody's going nuts. But we're still losing 1-0, which is horrible. Huston Street, who is the best closer in college baseball history, [is] in the bullpen and is going to come in.&nbsp;</p>
<p>Huston Street comes in and faces Enrique Cruz, who had some trouble a year before with sliders. Street throws a slider on the black, on the outside corner, and Cruz hits a line drive over the right field fence to tie the game. I mean, you couldn't have made a situation where I was more pumped up to this point.&nbsp;</p>
<p><strong>Greg Mitchell:</strong> There's like a vivid image in my mind and there's a picture of when Enrique crosses home plate after the home run. He goes like this [signaling horns down with his hands].&nbsp;</p>
<p><strong>Wade Townsend: </strong>When a team has a chance to beat you, and we come back and then we get to extra innings and we beat them, that really says a lot. It&rsquo;s like, oh, this time [it&rsquo;s] kind of for real this year.</p>
<p><strong>Kim Koehn:</strong> The Texas game was 10 innings and exciting. [But] we left for Hawaii the very next morning, so [the players] were back at the ballpark at 6 a.m. to get on the bus for our 9 a.m. flight.&nbsp;</p>
<p><em>After sweeping the University of Hawaii series, beating University of Nebraska-Lincoln and winning a tight 11-inning, 7-6 game against the University of Houston, Rice was 21-1. The streak was 18 games.&nbsp;</em></p>
<p style={{textAlign: 'center'}}><strong>Part 4: Invincibility</strong></p>
<p><strong>Jonathan Yardley:</strong> [The team] was so consistently outstanding. I always remember the 20-1 game [versus Liberty University], as ridiculous as it was to beat somebody [by that much]. [It was exciting to see] Jon Gillespie get in the game &hellip; He's our fourth-string catcher, a walk on, everybody loves the guy. He gets in and bats left-handed and he drags a single through the right side to drive in a run. Everybody always makes a joke. He is the leading hitter for the national champions because he was one-for-one.</p>
<p><strong>Wayne Graham, Rice baseball coach from 1992-2018</strong>: I'm sure that everybody loved that because Jon was a really good guy. We were happy for him. It's hard to start on the national championship team and you can't give everybody what they want.&nbsp;</p>
<p><strong>Wade Townsend: </strong>[We had] the twenty-fifth guy that actually wanted to be the twenty-fifth guy, that in no way wanted the [other] guys to fail. He knows he's not going to play, but he's thrilled to be there and help out. On another team, the twenty-fifth guy is just secretly rooting against the twenty-fourth guy who's definitely rooting against the twenty-third guy all the way down, and so you have a bunch of teammates that aren't even rooting for your own team to do well. We never had that problem.</p>
<p><strong>Kim Koehn: </strong>We had the opportunity to travel with 25 people. Every single person made every single trip.&nbsp;</p>
<p><strong>Greg Mitchell:</strong> Everyone had their role. Each person was special in their own way. It was not like a standard roster of the top high school players.</p>
<p><em>The Owls just kept winning. The Liberty series brought the streak to 21. A home stretch saw Rice take down Big 12 opponent Baylor 4-3, sweep San Jose State by a run differential of 27, shutout Houston 11-0 and win all three games against Hawaii. The streak had reached 29. Next up was Wade Townsend on the mound versus Texas A&amp;M University.&nbsp;</em></p>
<p><strong>Wade Townsend:</strong> I remember once we got to the thirtieth game against Texas A&amp;M at our house, and we go out there and I just dominate them and we just kicked the shit out of them. I remember thinking, we may never lose, like we were just beating the hell out of everybody. Then of course, we lost the next night.</p>
<p style={{textAlign: 'center'}}><strong>Part 5: Wednesdays</strong></p>
<p><strong>Greg Mitchell:</strong> One reason why streaks like this are hard is because of the weekday games. Weekday games were kind of the ones you had to just put together and sometimes there were two. I think this could be why the streak ended with the Lamar [University] game.</p>
<p><strong>Wade Townsend</strong>: Lamar was a top-ten team and nobody caught on [that] this team was good. Like, nowadays these smaller teams are starting to get the respect they deserve. Lamar back then, they were all on steroids, and they were all really good at baseball. They beat us twice that year, the only team to beat us twice.</p>
<p><strong>Wayne Graham:</strong> They beat us two games, and they broke the streak. I think some of our team may have been relieved.&nbsp; I was happy we got that many. You know, after a while, there is enough pressure to get to the College World Series and win.</p>
<p><strong>Kim Koehn:</strong> Losing to Lamar was kind of like okay, well, that was ugly, but the Fresno State one was a rowdy crowd and didn't feel good. I can remember [after the game] Phillip Humber got [in my minivan] and goes, &ldquo;Well great, now we're on a different streak.&rdquo; We had lost two games and [had] three losses of the season, and we automatically were just like, &lsquo;well, we're terrible.&rsquo;</p>
<p><strong>Wade Townsend:</strong> After the streak ended, we kind of took it a little bit off. You have a little bit of emotional letdown and we lost a couple of games over the next two weeks. Lamar beat us in Beaumont this time. On the bus right back home, a bunch of guys were having a roshambo contest. [The guys] were laughing and that pissed Coach Graham off because he was old school.</p>
<p>All of a sudden, they [tell us] to get off the bus. The assistant coaches are yelling at some people. I remember [assistant] coach [Mike] Taylor and [David] Pierce, I love both of them, but we're right in each other&rsquo;s faces. I said, &ldquo;We're going to win the fucking national championship.&rdquo; They both looked at me and said okay, and they laughed &hellip; I think they believed at that moment that we really were going to win it, and that was enough for them to drop it. They're like, &ldquo;You're damn right we are.&rdquo; It was just a weird moment where people are fighting, and then all of a sudden we're like, okay, no, we're not fighting. Let's go forward.</p>
<p>Our goal is to try to win every inning of every single game. You can if you actually think you can do it, and all of a sudden you start doing it, we went 30 in a row. That ain't easy to do, to win 30 in a row of anything.</p>
<p><em>After the streak ended, Rice went 15-8 over their final 23 games to close out the season. The Owls won their first three postseason games before a loss to Houston in the super-regionals put them a game away from elimination. But the Owls came back to beat the Cougars and punch their ticket to Omaha. By the end of June, Rice became National Champions after defeating Stanford 4-3, 3-8 and 14-2.&nbsp;</em></p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
