import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Rice addresses sexual health accessibility and campus recruitment after Dobbs";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Rice addresses sexual health accessibility and campus recruitment after Dobbs</h1>

                <div className="author-date">
                    <p id="author">By Prayag Gordy</p>
                    <p id="date" className="gray">Special Projects Editor  |  Nov. 30, 2022</p>
                </div>

                <p>Rice has purchased a vending machine to sell discounted contraceptives and has formed a task force for its launch, according to Dean of Undergraduates Bridget Gorman.</p>
                <p>Gorman, the co-chair of Rice&rsquo;s <a href="https://reproductivehealth.rice.edu/" target="_blank" rel="noopener">Reproductive Health Working Group</a>, said the task force consists of six undergraduate students, two graduate students, two staff members and two faculty members.</p>
                <p>The first vending machine, which has not yet arrived, will be located in Student Health Services, which is accessible at any time with a Rice ID, Gorman said. The task force will look at usage of the first machine and decide if and where to add additional machines, she added.</p>
                <p>The machines may store a combination of contraceptives and traditional vending machine merchandise in an attempt to decrease potential stigma of using the machine, Gorman said.</p>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/abortion/jennifer_liu_vending.png")} />
                </div>
                <p className="caption gray">Jennifer Liu / Thresher</p>

                <p>The RHWG has also created an FAQ to answer questions they received through a <a href="https://reproductivehealth.rice.edu/questionnaire" target="_blank" rel="noopener">form on their website</a>. As of publication, the RHWG has received 132 form submissions, Gorman said.</p>
                <p>&ldquo;It was not as much as I thought we would get,&rdquo; Gorman said. &ldquo;I was like, &lsquo;Are we going to get 1,000?&rsquo; But we didn&rsquo;t.&rdquo;</p>
                <p>Helena Michie, the director of the Center for the Study of Women, Gender and Sexuality, said people often have to feel safe and empowered before they complete a form about a controversial topic.</p>
                <p>&ldquo;You have to believe that the university or the people who have designed the form will listen to what you say, and will be able to act on those comments,&rdquo; Michie said. &ldquo;We are so constrained, I don't know how many people really felt that Rice had a choice, and I don't know how many people really felt that if Rice had a choice, that they would stand up for that choice.&rdquo;</p>
                <p>The submissions the RHWG did receive mostly fell into a <a href="https://reproductivehealth.rice.edu/frequently-asked-questions" target="_blank" rel="noopener">few common themes</a>, according to Amy Dittmar, the provost and the other co-chair of the working group. Many questions were legal, she said, while others asked about contraception, family care, medical leave and insurance.</p>
                <p>Rice provides a variety of family and medical leave options, including protections under Title IX. Rice&rsquo;s employee insurance plan reimburses travel over 100 miles for medical care, but Omar Syed, Rice&rsquo;s general counsel, said this likely does not apply to those on Rice&rsquo;s student plan.</p>
                <p>One of the most prevalent questions to the RHWG was about how to help someone with an unwanted pregnancy.</p>
                <p>&ldquo;That&rsquo;s a hard thing to answer on a webpage,&rdquo; Dittmar said. &ldquo;We provided some resources you can point people to which is, I think, the best thing to put on a public webpage. Obviously, there's other guidance you can imagine you might say, and we talk a little about being obviously compassionate and stuff, but it is this tricky area in some senses of how to advise somebody in this touchy legal landscape.&rdquo;</p>
                <p>Vanderbilt University, which is subject to <a href="https://www.findlaw.com/state/tennessee-law/tennessee-abortion-laws.html" target="_blank" rel="noopener">similarly restrictive abortion laws</a> in Tennessee, has a <a href="https://cm.maxient.com/reportingform.php?VanderbiltUniv&amp;layout_id=22" target="_blank" rel="noopener">Student Critical Support Fund</a>, which can be used to reimburse some costs of out of state medical care. It is unclear as of now if Rice students can use Rice&rsquo;s emergency funding resources for abortions, according to Syed.</p>
                <p>Student Health Services <a href="https://www.ricethresher.org/article/2022/08/as-roe-falls-rice-grapples-with-diminishing-abortion-access" target="_blank" rel="noopener">previously referred</a> students seeking abortions to Planned Parenthood. Syed said they can continue to provide lists of resources.</p>
                <p>Rice will continue to adapt its policies as court decisions clarify Texas&rsquo; abortion laws, Syed said. Among the biggest unknowns is the extent of the <a href="https://www.texastribune.org/2022/07/18/texas-abortion-laws-pressure-campaign/" target="_blank" rel="noopener">aiding and abetting</a> portion of <a href="https://capitol.texas.gov/tlodocs/87R/billtext/pdf/SB00008F.pdf" target="_blank" rel="noopener">Senate Bill 8</a>, which allows private citizens to sue anyone who assists a patient in seeking an abortion.&nbsp;</p>
                <p>&ldquo;We're likely to learn more about what the state courts think about these definitions and these terms in the coming years, because undoubtedly, there are going to be some lawsuits, and there are going to be some attempted prosecutions,&rdquo; Syed said. &ldquo;Then we'll get something that would more clearly spell out what obligations of latitude exist in the law.&rdquo;</p>
                <p>It is clear, Syed said, that Rice employees have no requirement to report students who break the law. That said, it is possible that employees may be sued if they help students access abortion care, Syed added.</p>
                <p>&ldquo;If that happens, obviously the university would review what is true and what is alleged and see what we can do to help,&rdquo; Syed said.</p>
                <p>Rice administrators are also worried about recruitment now that abortion is illegal in Texas. Attracting faculty, graduate students and undergraduate students may become more difficult.</p>
                <p>&ldquo;We&rsquo;re hearing examples of faculty that are pausing to rethink or faculty that might rethink coming here,&rdquo; Amy Dittmar, the provost and the other co-chair of the working group, said. &ldquo;It just means that we need to be even stronger in other ways so that we can hopefully negate some of the effects, but I&rsquo;m sure there&rsquo;s some people that are going to choose to not come.&rdquo;</p>
                <p>Gorman said the impacts on faculty recruitment and retention will be more clear after the end of the yearly hiring cycle.</p>
                <p>Some faculty, including Helena Michie, the director of the Center for the Study of Women, Gender and Sexuality, have chosen to not participate in recruitment. When prospective undergraduate students and their parents would tell Michie that they changed their mind about applying to Rice, Michie said she realized she had no rebuttal.</p>
                <p>&ldquo;I want to say &lsquo;yes, but Rice is great&rsquo; or &lsquo;yes, but Rice is very supportive of reproductive freedom,&rsquo; &lsquo;yes, but Houston is a blue city &hellip; and Rice is committed to the wellbeing of their students,&rsquo;&rdquo; Michie said. &ldquo;All of that is true, but I couldn&rsquo;t marshall it as an objection.&rdquo;</p>
                <p>Brian Riedel, the associate director of the CSWGS, said that Rice should also focus on domestic violence prevention, increasing access to contraceptives and expanding sex education.</p>
                <p>&ldquo;The concern for me is also to make sure that the Critical Thinking in Sexuality class does not become the only place that bears the burden of educating students about what&rsquo;s real, because that&rsquo;s a disservice to that class,&rdquo; Riedel said.</p>
                <p>Gorman noted that in addition to an optional sex education session to CTIS, Rice is partnering with the Baylor Teen Clinic to offer additional sex education classes.</p>

                <div className="large-quote" style={{ display: 'flex', justifyContent: 'center', margin: '0px 300px' }}>
                    <i>
                        {/* <h1 style={{ display: 'flex', justifyContent: 'left' }}> " </h1> */}
                        <h2 style={{ display: 'flex', justifyContent: 'center' }}> "We're hearing examples of faculty that are pausing to rethink or faculty that might rethink coming here. It just means that we need to be even stronger in other ways."</h2>
                        {/* <h1 style={{ display: 'flex', justifyContent: 'right' }}> " </h1> */}
                        <h2 style={{ display: 'flex', justifyContent: 'right' }}> Amy Dittmar, Rice University Provost </h2>
                    </i>
                </div>


                <p>Dittmar and Gorman said the RHWG is also focused on student mental health. Gorman lauded the Rice Wellbeing and Counseling Center, along with the SAFE Office, and said they would continue to adapt to student needs.</p>
                <p>&ldquo;I think we have the structure of support in place, but I do think as we move forward, if we are really seeing that there's a particular need around emotional support that we don't have &hellip; we'd be very open to adding support in, in really whatever way comes forward,&rdquo; Gorman said.</p>
                <p>Michie said she hoped Rice would have made a stronger statement of support for abortion rights.</p>
                <p>&ldquo;I think that the Reproductive Health Working Group is very devoted to the health of particularly students, and also staff and faculty and supporting their health,&rdquo; Michie said. &ldquo;That is very admirable, and it is the most immediate job of a university. I think the more important job as a university is to articulate values and to be independent of constraints opposed in a really violent and cruel way.&rdquo;</p>
                <p>Michie, who said she has been an administrator and understands the pressures of reporting to multiple stakeholders, added that Rice has been &ldquo;brave&rdquo; in opposing other Texas laws, such as COVID-19 policies and DACA restrictions, but not here.</p>
                <p>&ldquo;We are less constrained than [the University of Texas], we are less constrained than small private universities with a smaller endowment, we are less constrained than universities that are [more] dependent for research funds on the state legislature or state grants,&rdquo; Michie said. &ldquo;If we can&rsquo;t do it, what university can?&rdquo;</p>


                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
