import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Wayne Graham looks back at final seasons, start of program's decline";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Wayne Graham looks back at final seasons, start of program's decline</h1>

                <div className="author-date">
                    <p id="author">Pavithr Goli</p>
                    <p id="date" className="gray">Asst. Sports Editor  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/story 5 Thresher archives.jpg")} />
                </div>
                <p className="caption gray">Wayne Graham coaches during the 2018 season. Thresher archives.</p>

                <p>It is not often that a coach who led their school to win its first national championship in any sport is let go, but that was the case for former Rice baseball head coach Wayne Graham in 2018 when his contract was not renewed.&nbsp;</p>
<p>At the helm of the Rice baseball program for 26 years, Graham led the Owls to their only national championship in 2003 and seven other College World Series appearances. Graham, who was inducted into the College Baseball Hall of Fame in 2012, won 1,173 games during his tenure, transforming the Rice baseball program into one of the nation&rsquo;s best during his time on South Main.</p>
<p>Graham&rsquo;s exit from the Owls came as a surprise to him. As he <a href="https://www.usatoday.com/story/sports/college/baseball/2016/04/15/rices-80-year-old-baseball-coach-not-planning-to-slow-down/83086286/" target="_blank" rel="noopener">told the Associated Press</a> in 2016 &mdash; prior to his departure &mdash; Graham wanted to continue coaching, despite being over 80 years old.</p>
<p>&ldquo;Clint Eastwood still loves to direct movies at 85, and he&rsquo;s directing good ones,&rdquo; Graham said. &ldquo;Robert Duvall is [acting] at 85. If you&rsquo;re doing something you really like, unless you&rsquo;ve got something in retirement you&rsquo;d like better, why would you change?&rdquo;</p>
<p>According to Rice Athletic Director Joe Karlgaard, the decision to move on from Graham came around the end of the 2017 season.</p>
<p>&ldquo;I can&rsquo;t recall the exact month and year, but it was generally about a year out. You have to have some clarity on the future of the program,&rdquo; Karlgaard said. &ldquo;I had a couple of conversations with coach Graham about where he was with his contract and [that] we were going to move forward the following year. I&rsquo;m not sure there was clarity in his mind about it, but I began those conversations about a year [out].&rdquo;</p>
<p>Graham said that he did everything he could to stay.</p>
<p>&ldquo;You can&rsquo;t fight the top,&rdquo; Graham said. &ldquo;Their decision is their decision. I certainly wasn&rsquo;t asking for more money. There was no barrier [to me continuing] other than someone wanting change and thinking the program would benefit from a new face.&rdquo;</p>
<p>Despite the accolades that he accumulated throughout his coaching career at Rice, Graham said his final years in Houston left a sour taste in his mouth. In 2017, Graham&rsquo;s Owls went 33-31, the worst record at that point in his coaching career.</p>
<p>That season, the Owls entered the Conference USA tournament as the sixth seed out of eight teams. However, the Owls pulled together a miraculous string of performances to win the conference championship and put them back in the postseason.&nbsp;</p>
<p>According to former Rice pitcher Glenn Otto, the Owls were counted out by many, leading the team to embrace an underdog mentality to win the 2017 C-USA tournament.&nbsp;</p>
<p>&ldquo;This team, we&rsquo;re fighters, we&rsquo;re survivors,&rdquo; Otto said. &ldquo;That&rsquo;s what we&rsquo;ve done all season. Nobody has expected us to make a regional or win the conference or any of that but that&rsquo;s how we like it.&rdquo;</p>
<p>In a 2017 interview with <a href="https://www.ricethresher.org/article/2017/05/epic-turnaround-puts-rice-baseball-back-in-ncaa-tournament-v2" target="_blank" rel="noopener">Fox 26</a>, Graham said winning the tournament in 2017 was one of the best of his career.&nbsp;</p>
<p>&ldquo;It&rsquo;s up there with the [2003] national championship,&rdquo; Graham said. &ldquo;Nothing&rsquo;s that sweet.&rdquo;</p>
<p>The 2018 season, Graham&rsquo;s last at the helm of the Rice baseball program, was the worst of Graham&rsquo;s coaching career. It was Graham&rsquo;s first-ever losing season at any level, as the Owls went 26-31-2. With much of the public focused on Graham&rsquo;s future with the team, Graham believes that the rumors about whether or not he would stay had a negative impact on his players.</p>
<p>&ldquo;I felt there were rumors surrounding the program now that were affecting the players," Graham said. "I think when you clarify that and get it out in the open, now we can go about our business and proceed to get into the NCAA playoffs.&rdquo;</p>
<p>With his messy divorce from Rice taking place in the middle of the season, Graham emphasized, in a 2018 interview with the Houston Chronicle, that he wanted to divert the focus from his departure at Rice, and toward his team&rsquo;s performances.</p>
<p>&ldquo;I feel like we built a community treasure here,&rdquo; Graham said. &ldquo;It's been a great run. I'm trying not to think of anything but winning the next game, really I am, and helping my wife get through the anguish of what we've been going through.&rdquo;</p>
<p>Looking back at those seasons, Graham doesn&rsquo;t see all negatives, though. He still believes that he accomplished his two main goals of coaching his squad, despite the losing record and tensions at Reckling in his last season as the head coach.</p>
<p>&ldquo;I only have two goals at coaching, and this was consistent from the time I started coaching, and that's helping people get better help and winning,&rdquo; Graham said. &ldquo;Player development and winning are [the] only two goals that were kept pretty good [during the 2018 season].&rdquo;</p>
<p>Jose Cruz Jr., baseball&rsquo;s current head coach who played under Graham, said Graham&rsquo;s influence will always linger despite the unexpected end to his tenure.</p>
<p>&ldquo;He&rsquo;s a very relentless type of competitor,&rdquo; Jose Cruz Jr. said. &ldquo;He wants to do whatever possible to win. That kind of passion definitely rubs off on all the players.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
