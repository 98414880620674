import React from 'react'
import '../../stylings/AbortionHome.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function AbortionHome() {
    React.useEffect(() => {
        document.title = "Abortion through the ages";  
      }, []);

    return (
        <div>
        <div class="mainAbortionHome">
    
            <h1 class="project-title red-white center">ABORTION THROUGH THE AGES</h1>
            <p className="caption gray center">Published Nov. 30, 2022</p>
            <p className="caption gray center">Updated April 12, 2023</p>
      
            <div class='abortion-description'>
                <p>The Supreme Court overturned Roe v. Wade in June, ending 50 years of constitutional protections for abortion in the United States. Abortion in Texas is now illegal in almost all cases.</p>
                <p>Students at Rice sought abortions before Roe, during its reign and will continue in the wake of Roe's fall. The Thresher talked to alumni from the 1950s through the 2010s to build a timeline of relationships, sex and abortion at Rice. We also share our reporting on Rice's Reproductive Health Working Group, and features of abortion rights and anti-abortion groups on campus and in the Houston area.</p>
                <p>We will continue to cover abortion and reproductive health at Rice. If you have a story to share, reach out to <a href="mailto:thresher@rice.edu">thresher@rice.edu</a>.</p>
                <p id="design" class="red">Design by MyCo Le.</p>
            </div>
    
            <div class="articles standalone-articles">
                <div class="article left-article">
                    <a href="/projects/abortion/a-history-of-abortion-told-through-rice-alumni">
                        <img src={require("../../media/abortion/PlannedParenthood_homepage.png")} alt="" />
                    </a>
                    <a href="/projects/abortion/a-history-of-abortion-told-through-rice-alumni">
                        <div class="title-author red-white">
                            <h2>The timeline: a history of abortion, <br/>told through Rice alumni</h2>
                            <h4 class="author">Prayag Gordy, Danika Li, Andrea Plascencia <br/>and Caroline Mascardo</h4>
                        </div>
                    </a>
                </div>
                <div class="article right-article">
                    <a href="/projects/abortion/rice-addresses-sexual-health-accessibility-and-campus-recruitment-after-dobbs">
                        <img src={require("../../media/abortion/jennifer_liu_vending.png")} alt=""/>
                    </a>
                    <a href="/projects/abortion/rice-addresses-sexual-health-accessibility-and-campus-recruitment-after-dobbs">
                        <div class="title-author red-white">
                            <h2>The news: Rice addresses <br/>sexual health accessibility and <br/>campus recruitment after Dobbs</h2>
                            <h4 class="author">Prayag Gordy</h4>
                        </div>
                    </a>
                </div>
                <div class="article left-article">
                    <a href="/projects/abortion/rice-after-roe-abortion-perspectives-on-campus">
                        <img src={require("../../media/abortion/1.JPEG")} alt=""/>
                    </a>
                    <a href="/projects/abortion/rice-after-roe-abortion-perspectives-on-campus">
                        <div class="title-author red-white">
                            <h2>Rice after Roe: abortion <br/>perspectives on campus</h2>
                            <h4 class="author">Danika Li</h4>
                        </div>
                    </a>
                </div>
                <div class="article right-article">
                    <a href="/projects/abortion/two-at-a-time-lifehouse-bridges-the-gap">
                        <img src={require("../../media/abortion/Pregnant woman_Vivian Lang.jpg")} alt=""/>
                    </a>
                    <a href="/projects/abortion/two-at-a-time-lifehouse-bridges-the-gap">
                        <div class="title-author red-white">
                            <h2>'Two at a time': LifeHouse <br/>bridges the gap</h2>
                            <h4 class="author">Morgan Gage</h4>
                        </div>
                    </a>
                </div>
            </div>
            </div>
        </div>
    )
}
