import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Labor movement at Rice has stalled, organizers say";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Labor movement at Rice has stalled, organizers say</h1>

                <div className="author-date">
                    <p id="author">By Prayag Gordy and Allison Yue</p>
                    <p id="date" className="gray">Special Projects Editor and for the Thresher  |  Jan. 11, 2023</p>
                </div>

                <p>All work and no play makes a dull boy, the saying goes. Overworked and constantly tired, Gabby Franklin, the Student Association president, agrees.</p>
<p>&ldquo;That's the hardest part about negotiating with student labor,&rdquo; Franklin, a Brown College junior, said. &ldquo;We have a population of students who work, and not all of them are aware of the boundaries between you as a person and your commitment.&rdquo;</p>
<p>Students across the country are negotiating for more manageable working conditions. Some <a href="https://www.latimes.com/california/story/2022-11-30/read-our-full-coverage-of-the-uc-strike-of-48-000-academic-workers" target="_blank" rel="noopener">48,000 academic workers in the University of California</a> system went on strike to advocate for better conditions. Graduate students at Yale University recently <a href="https://yaledailynews.com/blog/2023/01/09/graduate-and-professional-student-workers-vote-to-unionize-in-landslide-election/" target="_blank" rel="noopener">voted to form a union</a> in their first election in 30 years.</p>
<p>But at Rice, student efforts to organize have fallen short.</p>
<div className='news-center-image'>
                    <img className='news-image' src={require("../../media/studentworkers/Jennifer_Liu_job2.jpeg")} />
                </div>
<p>One movement, led in part by senior Lily Sethre-Brink, prompted the formation of an SA task force, which dissolved at the end of one year with little to show. Now, with Sethre-Brink studying abroad, progress has stalled.</p>
<p>&ldquo;I think people just got overwhelmed and things didn't end up happening,&rdquo; Sethre-Brink said.</p>
<p>Sethre-Brink said she and a few other students started their effort with what they termed &ldquo;house chats.&rdquo; In these conversations, the organizers sat down with different labor groups on campus to learn about their working conditions and aspirations.</p>
<p>A &ldquo;labor group,&rdquo; at least in Sethre-Brink&rsquo;s eyes, covers a large variety of student activities on campus. Working at the bookstore, in Coffeehouse or as a student maintenance representative count &mdash;&nbsp;but so does volunteering in an affinity group, they explained.</p>
<p>&ldquo;A lot of the work to address our issues falls on students,&rdquo; Sethre-Brink said. &ldquo;A lot of the work, for example, to address homophobia and transphobia falls on Rice Pride. A lot of the work to address anti-Blackness falls on BSA and RASA, especially organizing cultural events and things like that. It's a huge part of education &hellip; That is labor, that is student labor.&rdquo;</p>
<p>Sethre-Brink and the other organizers continued with house chats through the beginning of the COVID-19 pandemic. Last year, the SA formed the <a href="https://twitter.com/RiceUnivSA/status/1493284548582514694" target="_blank" rel="noopener">Student Labor Task Force</a> with Sethre-Brink among its leaders. Now dissolved, the task force collected information to characterize the state of student labor at Rice.</p>
<p>&ldquo;The task force was &hellip; looking at paid and unpaid roles, looking at the extent of what students are working on and what students do on campus,&rdquo; Solomon Ni, now the SA treasurer, said.&nbsp;</p>
<p>Ni said the task force&rsquo;s preliminary findings suggested that many student positions are unpaid.</p>
<p>&ldquo;There are a lot of student roles on campus that are unpaid in general, from peer academic advisors to academic fellows to RHAs,&rdquo; Ni, a Jones College sophomore, said. &ldquo;These are still people that produce labor or produce work that they had to take time out of their week to do.&rdquo;</p>
<p>But the task force dissolved before producing significant results.</p>
<p>&ldquo;We never were able to finalize our conclusions,&rdquo; Ni said.</p>
<p>Little has happened while Sethre-Brink has been abroad. Since students generally move on after four or five years at Rice, progress tends to be slow, which Sethre-Brink called the &ldquo;administration&rsquo;s biggest weapon.&rdquo;</p>
<p>&ldquo;I think people just got overwhelmed and things didn't end up happening, which I think is part of why it's so important for organizing to be sustainable,&rdquo; Sethre-Brink said. &ldquo;I think we were genuinely doing the work to build those relationships so that it's not reliant on one particular group of people, but I think we just didn't do a good enough job of that.&rdquo;</p>
<p>What comes next is not yet clear. Franklin said the Student Association was originally a form of a student labor union &mdash;&nbsp;&rdquo;It&rsquo;s called the student government association&rdquo; &mdash; but unclear communication dampened its effectiveness. Ni said student workers should take the lead.</p>
<p>&ldquo;I think student workers have an important voice,&rdquo; Ni said. &ldquo;They should be able to organize themselves because they are the ones that know best about their condition.&rdquo;</p>
<p>Regardless of the mechanism, Ni said it is crucial for students to organize.</p>
<p>&ldquo;Rice is a community that relies a lot on student labor,&rdquo; Ni said. &ldquo;Yes, I think there is a need for labor organizing at Rice, I'll be very like upfront.&rdquo;</p>

                <p id="author">Illustration by Jennifer Liu. Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
