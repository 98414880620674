import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Rice considered structural changes to athletics in 2004 but stuck with status quo";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Rice considered structural changes to athletics in 2004 but stuck with status quo</h1>

                <div className="author-date">
                    <p id="author">Prayag Gordy</p>
                    <p id="date" className="gray">Special Projects Editor  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/story 4 Screenshot of McKinsey report.png")} />
                </div>
                <p className="caption gray">Annual athletics deficits increased to more than $10 million by 2003. The Board of Trustees declared that reducing the deficit would be a priority. Screenshot from Intercollegiate Athletics at Rice University report.</p>

                <p>As Rice baseball made its way to a national championship in 2003, the Board of Trustees commissioned a study on the future of Rice athletics. The report asked if intercollegiate sports had a place at Rice at all, and if so, in what capacity.</p>
<p>The study, completed by the management consulting firm McKinsey &amp; Company, came on the heels of a report conducted by the Faculty Senate.</p>
<p>That report highlighted the high costs of Rice&rsquo;s athletics programs &mdash; which, as McKinsey later determined, ran an annual deficit of more than $10 million &mdash; the relatively low SAT scores of athletic applicants and the disproportionately higher level of rule-breaking for athletes compared to nonathletes. The Faculty Senate&rsquo;s report did find that many of Rice&rsquo;s student-athletes did well as students and as athletes.</p>
<p>McKinsey declared that dropping varsity sports entirely was too extreme, especially since all of Rice&rsquo;s peer institutions in the Consortium on Financing Higher Education and the American Association of Universities had athletics programs. Their report did offer four &ldquo;viable&rdquo; alternatives: remaining in Division I-A and working to improve athletics, moving to Division I-AA and shifting to non-scholarship football, moving to Division I-AAA and dropping football and moving to Division-III with no scholarship athletes.</p>
<p>Some fans formed a group to protest any potential move from Division I-A, &ldquo;Friends of Rice Athletics.&rdquo; They bought newspaper ads, wrote letters and threatened to withhold donations if Rice dropped its football program.</p>
<p>Bobby May, Rice&rsquo;s athletic director at the time, was a vocal supporter of remaining in Division I-A. He claimed there were oversights in the McKinsey report, and that top-tier athletics were a fundamental part of Rice.</p>
<p>"Rice is Rice because, at least in part, of what I-A athletics brings to the university," May told the Thresher in May 2004. "If you tinkered with that at all, you'd change the character of the university. Rice would still be a great institution, but with a different personality and culture, and the one that's there now is pretty special."</p>
<p>Later that month, the Board of Trustees announced that Rice would remain in Division I-A, but with a focus on reducing the athletic deficit, primarily through fundraising.</p>
<p>The Friends of Rice Athletics were pleased by the decision. The Faculty Senate, however, was not.</p>
<p>&ldquo;After all the turmoil and angst &hellip; I just thought we were poised for some change, and I thought [the board was] intending to really make some changes, so I was surprised,&rdquo; Faculty Council athletics subcommittee chair Marj Corcoran told the Thresher in August 2004.</p>
<p>The Board decided against three other options presented by McKinsey. The most significant change would have been a move to Division III sports. Athletic scholarships would be prohibited, and the shift would generally be a &ldquo;wholesale change,&rdquo; McKinsey said.</p>
<p>&ldquo;Moving the Rice athletics program to Division III (with or without football) would be based on the decision that the competitive demands and economic realities of Division I are incompatible with Rice&rsquo;s academic mission,&rdquo; the report said.</p>
<p>Rice could also have considered moving to Division I-AA, which would place them in a non-scholarship football league. However, McKinsey determined that this option had its drawbacks, especially regarding conference selection.</p>
<p>&ldquo;Despite such potential benefits, a move to Division I-AA for Rice would be complicated by a lack of appreciable net cost savings, upset constituents, and, most importantly, conference realignment,&rdquo; McKinsey wrote. &ldquo;At a minimum, moving to Division I-AA would necessitate that Rice find a new conference in which to play football.&rdquo;</p>
<p>McKinsey said that Rice could have done away with football entirely by dropping to Division I-AAA. Football was the biggest contributor to Rice&rsquo;s athletic debt, and McKinsey wrote that Rice would face &ldquo;ever-increasing difficulty attempting to excel in football.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
