import './App.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from './pages/general/Homepage';
// import Dummypage from './pages/Dummypage';
// import InteractiveTemplate from './pages/InteractiveStory';
// import Standardpage from './pages/StandardTemplate';
import NavBar from './components/general/NavBar';
import "./stylings/styleAH.css";
import "./stylings/styleJP.css";
import "./stylings/styleMap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import ScrollStory from './pages/ScrollStory';
// import InteractiveStory from './pages/InteractiveStory';
import Footer from './components/general/footer';
// import Multimediapage from './pages/MultimediaTemplate';
import MapPage from './pages/ion/Data/MapPage';
import IonHome from './pages/ion/innovation/IonHome';
import IonNews from './pages/ion/innovation/InnovationPage';
import IonFeatures from './pages/ion/features/IonFeatures';
import Bensaci from './pages/ion/features/feature-articles/bensaci';
import Doshi from './pages/ion/features/feature-articles/doshi';
import Edwards from './pages/ion/features/feature-articles/edwards';
import Freeman from './pages/ion/features/feature-articles/freeman';
import Jones from './pages/ion/features/feature-articles/jones';
import Rush from './pages/ion/features/feature-articles/rush';
import Vallie from './pages/ion/features/feature-articles/vallie';
import AandEpage from './pages/ion/ArtsEntertainment/ArtsEntertainment'

import AbortionHome from './pages/abortion/AbortionHome';
import AbortionTimeline from './pages/abortion/AbortionTimeline';
import AbortionNews from './pages/abortion/abortionNews';
import AbortionPro from './pages/abortion/abortionPro';
import AbortionAnti from './pages/abortion/abortionAnti';

import SWHomePage from './pages/studentworker/SWHomePage';
import SW_OWeek from './pages/studentworker/oweek';
import SW_labor from './pages/studentworker/labor';
import SW_crisis from './pages/studentworker/times_of_crisis';
import SW_fgli from './pages/studentworker/fgli';

import BaseballHome from './pages/baseball/BaseballHome';
import Baseball1 from './pages/baseball/baseball1';
import Baseball2 from './pages/baseball/baseball2';
import Baseball3 from './pages/baseball/baseball3';
import Baseball4 from './pages/baseball/baseball4';
import Baseball5 from './pages/baseball/baseball5';
import Baseball6 from './pages/baseball/baseball6';
import Baseball71 from './pages/baseball/baseball71';
import Baseball72 from './pages/baseball/baseball72';
import Baseball73 from './pages/baseball/baseball73';
import Baseball74 from './pages/baseball/baseball74';
import Baseball75 from './pages/baseball/baseball75';

import ReactGA from "react-ga4";
ReactGA.send({hitType: "pageview", page: window.location.pathname})

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path='/' element={<Homepage />}> </Route>
        <Route path='/projects' element={<Homepage />}> </Route>
        {/* <Route path='/stories' element={<Dummypage />}></Route> */}
        {/* <Route path='/scroll' element={<ScrollStory />}></Route> */}
        {/* <Route path='/interactive' element={<InteractiveStory />}></Route> */}
        {/* <Route path='/multimediastory' element={<Multimediapage/>}></Route> */}
        {/* <Route path='/dummystory' element={<Dummypage/>}></Route> */}
        {/* <Route path='/standardstory' element={<Standardpage/>}></Route> */}
        <Route path='/projects/innovation' element={<IonHome/>}></Route>
        <Route path='/projects/innovation/rices-ion-settles-into-third-ward' element={<IonNews/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors' element={<IonFeatures/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-bensaci' element={<Bensaci/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-doshi' element={<Doshi/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-edwards' element={<Edwards/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-freeman' element={<Freeman/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-jones' element={<Jones/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-rush' element={<Rush/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/ion-feature-vallie' element={<Vallie/>}></Route>
        <Route path='/projects/innovation/meet-rices-newest-neighbors/art-and-integration-houstons-tradition-of-art-challenging-the-paradigm' element={<AandEpage/>}></Route>
        <Route path='/projects/innovation/the-data-are-unclear-on-ions-gentrification' element={<MapPage/>}></Route>

        <Route path='/projects/abortion' element={<AbortionHome/>}></Route>
        <Route path='/projects/abortion/a-history-of-abortion-told-through-rice-alumni' element={<AbortionTimeline/>}></Route>
        <Route path='/projects/abortion/rice-addresses-sexual-health-accessibility-and-campus-recruitment-after-dobbs' element={<AbortionNews/>}></Route>
        <Route path='/projects/abortion/rice-after-roe-abortion-perspectives-on-campus' element={<AbortionPro/>}></Route>
        <Route path='/projects/abortion/two-at-a-time-lifehouse-bridges-the-gap' element={<AbortionAnti/>}></Route>

        <Route path='/projects/studentworkers' element={<SWHomePage/>}></Route>
        <Route path='/projects/studentworkers/o-week-coords-talk-work-stress-and-pay' element={<SW_OWeek/>}></Route>
        <Route path='/projects/studentworkers/labor-movement-at-rice-has-stalled-organizers-say' element={<SW_labor/>}></Route>
        <Route path='/projects/studentworkers/fgli-student-leaders-tackle-the-hidden-curriculum' element={<SW_fgli/>}></Route>
        <Route path='/projects/studentworkers/crisis-and-damage-control-rice-student-leaders-uphold-a-culture-of-care' element={<SW_crisis/>}></Route>

        <Route path='/projects/baseball' element={<BaseballHome/>}></Route>
        <Route path='/projects/baseball/player-development-discipline-fueled-baseballs-rise-in-early-Graham-years' element={<Baseball1/>}></Route>
        <Route path='/projects/baseball/consistent-excellence-an-oral-history-of-baseballs-2003-30-game-win-streak' element={<Baseball2/>}></Route>
        {/* <Route path='/projects/baseball/player-development-discipline-fueled-baseballs-rise-in-early-Graham-years' element={<Baseball3/>}></Route> */}
        <Route path='/projects/baseball/rice-considered-fundamental-changes-to-athletics-in-2004-but-stuck-with-status-quo' element={<Baseball4/>}></Route>
        <Route path='/projects/baseball/wayne-graham-looks-back-at-final-seasons-start-of-programs-decline' element={<Baseball5/>}></Route>
        <Route path='/projects/baseball/bragga-brought-positive-attitude-priority-shifts-to-his-disappointing-tenure-at-rice' element={<Baseball6/>}></Route>
        <Route path='/projects/baseball/janish-reflects-on-his-unexpectedly-long-tenure-with-rice' element={<Baseball71/>}></Route>
        <Route path='/projects/baseball/philip-humber-revisits-a-career-of-ups-and-downs' element={<Baseball72/>}></Route>
        <Route path='/projects/baseball/i-have-the-ability-to-control-time-wade-townsend-talks-baseball-poker-his-spiritual-awakening' element={<Baseball73/>}></Route>
        <Route path='/projects/baseball/twenty-years-after-catching-last-out-sinisi-reflects-on-differences-between-college-minors' element={<Baseball74/>}></Route>
        <Route path='/projects/baseball/years-after-career-ending-injury-jeff-niemann-and-baseball-are-friends-again' element={<Baseball75/>}></Route>
      </Routes>
      <div className='footer'>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
