import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Crisis and damage control: Rice student leaders uphold a culture of care";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Crisis and damage control: Rice student leaders uphold a culture of care</h1>

                <div className="author-date">
                    <p id="author">By Prayag Gordy and Ellie Cha</p>
                    <p id="date" className="gray">Special Projects Editor and for the Thresher  |  Nov. 30, 2022</p>
                </div>


                <p>Rice students manage significant college budgets, organize large events and plan the orientation of every new student. Student leaders say they appreciate the opportunity to shape their community &mdash;&nbsp;but when something goes wrong, who is left in charge?</p>
<p>The Thresher spoke to three student leaders who faced unique crises or prolonged challenges. It was not easy, they said, but the impact they made proved to be valuable.</p>
<p style={{textAlign: 'center'}}>Finding footing after loss</p>
<p>Dan Helmeci was quickly ushered into a meeting with Bridget Gorman, the dean of undergraduates, back in October. Gorman had called in Helmeci, the Jones College president, to inform him of the <a href="https://www.ricethresher.org/article/2022/10/ryan-dullea-jones-sophomore-remembered" target="_blank" rel="noopener">death of a Jones student</a>.</p>
<p>&ldquo;That day, Dean Gorman told me face to face, and at that point, I was the first student to know,&rdquo; Helmeci, a senior, said.</p>
<p>Helmeci said that he was inexperienced in handling such a difficult situation, but he was prepared to help in any way that he could. He became a spokesperson for the hard announcements while representing student voices to administrators.</p>
<p>&ldquo;It was a lot of figuring out how to tell students [about] well-being resources that we knew had to go into effect pretty quickly,&rdquo; Helmeci said. &ldquo;I was one of the people helping to identify the deceased&rsquo;s closest friends and people we really wanted to reach out to individually and make sure that they had the kind of support they needed.&rdquo;&nbsp;</p>
<div className='news-center-image'>
                    <img className='news-image' src={require("../../media/studentworkers/katherine_hui.JPG")} />
                </div>
                <p className="caption gray">Helmeci took care of the Jones community while grieving himself. Katherine Hui / Thresher</p>

<p>As more time passes, Helmeci said he&rsquo;s grown concerned that student leaders are occasionally put in situations they are not adequately prepared to handle.</p>
<p>&ldquo;At the end of the day, we&rsquo;re not trained for it,&rdquo; Helmeci said. &ldquo;We could be doing more damage than good, and we don&rsquo;t really know that.&rdquo;&nbsp;</p>
<p>Broadly, however, Helmeci said student leaders should continue to be involved in important decisions. Not doing so, he said, would make it just that much harder to support students.</p>
<p style={{textAlign: 'center'}}>An impossible task</p>
<p>Maddie Scannell from the <a href="https://safe.rice.edu/resources/undergraduate-strive-students-transforming-rice-violence-free-environment" target="_blank" rel="noopener">STRIVE Coalition</a> says the way Rice addresses on-campus sexual and domestic violence resources is unfeasible. From the get-go, she knew there would be expectations required of her that she couldn&rsquo;t fulfill completely.</p>
<p>&ldquo;I knew what I was signing up for,&rdquo; Scannell (&lsquo;21) said. &ldquo;I went into the situation with a lot of clarity [that my job] is an impossible ask.&rdquo;</p>
<p>Scannell said the severity of situations the undergraduate STRIVE liaisons had to confront were over and above what any 18 to 22-year-old student should be expected to confront.</p>
<p>&ldquo;Sometimes we would step back and be like, &lsquo;Oh my god, why am I the point person for this?&rsquo;&rdquo; Scannell said.&nbsp;</p>
<p>While liaisons were trained to refer at-risk students to appropriate professional help, Scannell explained that she still had to sacrifice her personal time despite adequate support from the SAFE office. If a student urgently calls her at 2 a.m., Scannell said she would feel awful turning the caller to somebody else.&nbsp;</p>
<p>Such examples of times of crisis resulted in her own mental health ups and downs, but Scannell said the job was not without its personal value.</p>
<p>&ldquo;There were a couple instances where I felt super responsible for someone else&rsquo;s safety and as a result, my [mental] health wasn&rsquo;t great,&rdquo; Scannell said. &ldquo;[But] there&rsquo;s something really restorative about helping people go through difficult situations.&rdquo;</p>
<p style={{textAlign: 'center'}}>'We wanted that kind of control'</p>
<p>Despite the hurdles imposed by COVID-19, Ishaan Rischie, a student director of Orientation Week 2021, said his team&rsquo;s detailed planning prevented what could have been a more stressful week. Rice reported <a href="https://www.ricethresher.org/article/2021/08/rice-reports-around-50-students-as-positive-due-to-test-site-error-disrupting-o-week-activities" target="_blank" rel="noopener">approximately 50 COVID positive cases</a> that halted all student activities that year; later on, these cases were revealed to be test-site errors.</p>
<p>&ldquo;When we started seeing all those cases, we wanted to make sure that what we were doing was still in line with keeping everybody safe,&rdquo; Rischie, who graduated from Jones in 2022, said. Rischie explained how O-Week is such an important transition point for new students so making &lsquo;work-arounds&rsquo; to ensure everyone&rsquo;s fun and safety was a priority.</p>
<p>Looking back on his undergraduate career, Rischie said he always felt a certain expectation forhimself and in the work he pursued. He said that many student leaders he&rsquo;s interacted with share the same sentiment about intentionality: you sign up to do well in your job.</p>
<p>&ldquo;I don&rsquo;t think there was any external pressure where I had to perform some sort of way,&rdquo;Rischie said. &ldquo;I think most of that pressure just came from our own internal pressure.We wanted to make sure that we were still putting on this product and making sure we were supporting people.&rdquo;</p>
<p>Rischie said he took on additional responsibility during O-Week to keep his mind busy from the COVID chaos, and he and his co-director requested more autonomy to create an unforgettable O-Week experience.&nbsp;</p>
<p>&ldquo;If anything, I think we were adding more work on our platter because we wanted that kind of control,&rdquo; Rischie said. &ldquo;There is a need for this autonomy because we&rsquo;re the ones that are on the ground, seeing the problems and able to deal with it because we understand what&rsquo;s going on.&rdquo;</p>

                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
