import React, {useEffect, useState} from 'react';
import '../../stylings/BaseballHome.css'
import ReactVivus from 'react-vivus';
import stadium from '../../media/rice-baseball/stadium.svg'
import baseball from '../../media/rice-baseball/baseball.svg'
import starBg from '../../media/rice-baseball/star-bg.png'
import frames from '../../media/rice-baseball/baseball-sprites.svg'
import humber from '../../media/rice-baseball/framed/humber.jpg'
import janish from '../../media/rice-baseball/framed/janish.jpg'
import townsend from '../../media/rice-baseball/framed/townsend.jpg'
import sinisi from '../../media/rice-baseball/framed/sinisi.jpg'
import niemann from '../../media/rice-baseball/framed/niemann.jpg'
import wood from '../../media/rice-baseball/Oak-Architextures.jpg'
import trophy from '../../media/rice-baseball/framed/trophy.svg'
import pane1 from '../../media/rice-baseball/pane1.png'
import pane2 from '../../media/rice-baseball/pane2.png'
import pane3 from '../../media/rice-baseball/pane3.png'

export default function BaseballHome() {

    const [windowData, setWindowData] = useState({
        scrollY: 0,
        preTop: 10,
        height: window.innerHeight,
        width: window.innerWidth,
        shelfScrollable: 0
    });

    let articleList = [
        {
            title: "Player development, discipline fueled baseball's rise in early Graham years",
            link: "/projects/baseball/player-development-discipline-fueled-baseballs-rise-in-early-Graham-years",
            attr: "Diego Palos Rodriguez, Senior Writer"
        },
        {
            title: "“Consistent excellence”: an oral history of baseball's 2003 30-game win streak",
            link: "/projects/baseball/consistent-excellence-an-oral-history-of-baseballs-2003-30-game-win-streak",
            attr: "Chloe Singer, Thresher staff"
        },
        {
            title: "Rice considered fundamental changes to athletics in 2004 but stuck with status quo",
            link: "/projects/baseball/rice-considered-fundamental-changes-to-athletics-in-2004-but-stuck-with-status-quo",
            attr: "Prayag Gordy, Special Projects Editor"
        },
        {
            title: "Wayne Graham looks back at final seasons, start of program's decline",
            link: "/projects/baseball/wayne-graham-looks-back-at-final-seasons-start-of-programs-decline",
            attr: "Pavithr Goli, Asst. Sports Editor"
        },
        {
            title: "Bragga brought 'positive attitude,' priority shifts to his disappointing tenure at Rice",
            link: "/projects/baseball/bragga-brought-positive-attitude-priority-shifts-to-his-disappointing-tenure-at-rice",
            attr: "Murtaza Kazmi, Thresher staff"
        }
    ]

    let featureList = [
        {
            title: "Janish reflects on his unexpectedly long tenure with Rice baseball",
            link: "/projects/baseball/janish-reflects-on-his-unexpectedly-long-tenure-with-rice",
            attr: "Daniel Schrager, Sports Editor",
            img: janish,
            height: '30vh',
            width: '24vh'
        },
        {
            title: "Philip Humber revisits a career of ups and downs",
            link: "/projects/baseball/philip-humber-revisits-a-career-of-ups-and-downs",
            attr: "Landry Wood, Thresher staff",
            img: humber,
            height: '30vh',
            width: '24vh'
        },
        {
            title: "‘I have the ability to control time’: Wade Townsend talks baseball, poker, his spiritual awakening",
            link: "/projects/baseball/i-have-the-ability-to-control-time-wade-townsend-talks-baseball-poker-his-spiritual-awakening",
            attr: "Cadan Hanson, Senior Writer",
            img: townsend,
            height: '30vh',
            width: '37.5vh'
        },
        {
            title: "20 years later, Vincent Sinisi reflects on baseball, catching last out",
            link: "/projects/baseball/twenty-years-after-catching-last-out-sinisi-reflects-on-differences-between-college-minors",
            attr: "Riya Misra, Features Editor",
            img: sinisi,
            height: '30vh',
            width: '24vh'
        },
        {
            title: "Years after career-ending injury, Jeff Niemann and baseball are friends again",
            link: "/projects/baseball/years-after-career-ending-injury-jeff-niemann-and-baseball-are-friends-again",
            attr: "Daniel Schrager, Sports Editor",
            img: niemann,
            height: '30vh',
            width: '24vh'
        }
    ];

    let articleNum = -1;
    let articleTop = 0;

    let vh = windowData.height / 100;
    let preY = windowData.scrollY - windowData.preTop;
    let preVh = preY / vh;
    let batterVh = preVh - 420;
    let baseballVh = batterVh - 400;
    let fieldVh = baseballVh - 233;
    let shelfVh = fieldVh - 366;

    // console.log(shelfVh);
    if (batterVh >= 5 && batterVh < 128) {
        articleTop = 90 - batterVh * 0.5;
        articleNum = 0;
    } else if (batterVh >= 138 && batterVh < 261) {
        articleTop = 90 - (batterVh - 133) * 0.5;
        articleNum = 1;
    } else if (baseballVh >= 5 && baseballVh < 128) {
        articleNum = 2;
        articleTop = 90 - (baseballVh * 0.5);
    } else if (fieldVh >= 5 && fieldVh < 128) {
        articleNum = 3;
        articleTop = 90 - (fieldVh * 0.5);
    } else if (fieldVh >= 138 && fieldVh < 261) {
        articleNum = 4;
        articleTop = 90 - (fieldVh - 133) * 0.5;
    }

    let header1Class = "non-existent";
    let header2Class = "non-existent";
    let header3Class = "non-existent";
    let pane1Class = "";
    let pane2Class = "";
    let pane3Class = "";
    if (preVh < 0) {
        header1Class = "";
    } else if (preVh >= 0 && preVh < 75) {
        header1Class = "";
        pane1Class = "visible";
    } else if (preVh >= 75 && preVh < 80) {
        header1Class = "hidden";
        pane1Class = "visible";
    } else if (preVh >= 80 && preVh < 85) {
        header2Class = "hidden";
        pane1Class = "visible";
    } else if (preVh >= 85 && preVh < 155) {
        header2Class = "";
        pane1Class = "distorted1";
        pane2Class = "visible";
    } else if (preVh >= 155 && preVh < 160) {
        header2Class = "hidden";
        pane1Class = "distorted1";
        pane2Class = "visible";
    } else if (preVh >= 160 && preVh < 165) {
        header3Class = "hidden";
        pane1Class = "distorted1";
        pane2Class = "visible";
    } else if (preVh >= 165) {
        header3Class = "";
        pane1Class = "distorted2";
        pane2Class = "distorted1";
        pane3Class = "visible";
    }
    
    // Baseball animation
    let baseballXMovement = 0;
    
    // Headline animation
    let batter1Class = "non-existent";
    let batter2Class = "non-existent";
    if (batterVh >= 0 && batterVh < 5) {
        batter1Class = "hidden";
    } else if (batterVh >= 5 && batterVh < 128) {
        batter1Class = "";
    } else if (batterVh >= 128 && batterVh < 133) {
        batter1Class = "hidden";
    } else if (batterVh >= 133 && batterVh < 138) {
        batter2Class = "hidden";
    } else if (batterVh >= 138 && batterVh < 261) {
        batter2Class = "";
    } else if (batterVh >= 261) {
        batter2Class = "hidden";
    }
    
    // Frame animation
    let baseballPos = 10;
    const frameFactor = 83.2389580974;
    const vhBaseballSF = 40;
    const scrollStep = 26.6;
    if (batterVh > scrollStep) {
        if (batterVh < (scrollStep * 2)) {
            baseballXMovement = -0.4 * (windowData.width / 2) + (vh * vhBaseballSF) + "px";
            baseballPos -= frameFactor;
        } else if (batterVh < (scrollStep * 3)) {
            baseballXMovement = -0.6 * (windowData.width / 2) + (vh * vhBaseballSF) + "px";
            baseballPos -= frameFactor * 2;
        } else if (batterVh < (scrollStep * 4)) {
            baseballXMovement = -0.8 * (windowData.width / 2) + (vh * vhBaseballSF) + "px";
            baseballPos -= frameFactor * 3;
        } else if (batterVh < (scrollStep * 5)) {
            baseballXMovement = -1 * (windowData.width / 2) + (vh * vhBaseballSF) + "px";
            baseballPos -= frameFactor * 4;
        } else if (batterVh < (scrollStep * 6)) {
            baseballXMovement = -1.2 * (windowData.width / 2) + (vh * vhBaseballSF) + "px";
            baseballPos -= frameFactor * 5;
        } else if (batterVh < (scrollStep * 7)) {
            baseballPos -= frameFactor * 6;
        } else if (batterVh < (scrollStep * 8)) {
            baseballPos -= frameFactor * 7;
        } else {
            baseballPos -= frameFactor * 8;
        }
    }

    let field1Class = "non-existent";
    let field2Class = "non-existent";
    if (fieldVh >= 0 && fieldVh < 5) {
        field1Class = "hidden"
    } else if (fieldVh >= 5 && fieldVh < 128) {
        field1Class = ""
    } else if (fieldVh >= 128 && fieldVh < 133) {
        field1Class = "hidden"
    } else if (fieldVh >= 133 && fieldVh < 138) {
        field2Class = "hidden"
    } else if (fieldVh >= 138) {
        field2Class = ""
    }

    let fieldGrayscale = 0;
    let fieldHueDeg = 0;
    if (fieldVh >= 0 && fieldVh < 150) {
        fieldGrayscale = fieldVh * 2 / 3;
    } else if (fieldVh >= 150) {
        fieldHueDeg = 37.5;
        fieldGrayscale = 100;
    }

    // Did some calculus for this section
    // idk why
    let y = 0;
    let x = shelfVh;
    if (shelfVh >= 0 && shelfVh < 50) {
        y = (x**2)/100;
    } else if (shelfVh >= 50 && shelfVh < 350) {
        y = x - 25;
    } else if (shelfVh >= 350 && shelfVh < 400) {
        y = (8*x) - (x**2)/100 - 1250;
    } else if (shelfVh >= 400) {
        y = 350;
    }
    if (document.getElementById('shelf-contents')) {
        document.getElementById('shelf-contents').scrollLeft = y * windowData.shelfScrollable / 350;
    }

    useEffect(() => {
        document.title = "The Rice Owls Saga";

        function handleChange() {
            let preSection = document.getElementById('pre-animation-container');
            let shelfScroll = document.getElementById('shelf-contents');
            if (preSection != null) {
                setWindowData({
                    scrollY: window.pageYOffset,
                    preTop: preSection.offsetTop,
                    height: window.innerHeight,
                    width: window.innerWidth,
                    shelfScrollable: shelfScroll.scrollWidth - window.innerWidth
                });
            }
        }
        window.addEventListener('resize', handleChange);
        window.addEventListener('scroll', handleChange);

        return _ => {
            window.removeEventListener('resize', handleChange)
            window.removeEventListener('scroll', handleChange)
        }
    });
    return (
        <div id='baseball-container'>
            {articleList.map((article, idx) => {
                return(
                <a key={idx} className={`article-link-container ${(idx == articleNum) ? 'visible' : ''}`} href={article.link} style={{
                    top: `${(idx == articleNum) ? articleTop : 100}vh`,
                    left: ['2%', undefined][idx % 2],
                    right: [undefined, '2%'][idx % 2]
                }}>
                    <h3>{article.title}</h3>
                    <div id="article-attribution">{article.attr}</div>
                </a>
                )
            })}
            <div id='pre-animation-container'>
                <div id='pre-animation-sub'>
                    <div id='header-bg-pane-container'>
                        <div className={`header-bg-pane bg-pane-1 ${pane1Class}`} style={{
                            backgroundImage: `url(${pane1})`
                        }}></div>
                        <div className={`header-bg-pane bg-pane-2 ${pane2Class}`} style={{
                            backgroundImage: `url(${pane2})`
                        }}></div>
                        <div className={`header-bg-pane bg-pane-3 ${pane3Class}`} style={{
                            backgroundImage: `url(${pane3})`
                        }}></div>
                    </div>
                    <div id='h1-txt-container'>
                        <h1 className={header1Class}>Rice baseball struggled for 79 years.</h1>
                        <h1 className={header2Class}>Then, they won the national championship.</h1>
                        <h1 className={header3Class}>20 years later, Owls reassess their only ultimate victory.</h1>
                    </div>
                </div>
            </div>
            <div id='blurb-container'>
                <p className='blurb-text'>
                    Twenty years ago this June, Rice won its only national title in a team sport, the 2003 College Baseball World Series. In the 14 years that followed, the Owls made the NCAA regionals every season. But the six years since have seen the reign of long-time head coach Wayne Graham draw to a close and the short-lived tenure of his successor, Matt Bragga, come and go, as the Owls failed to qualify for even the conference tournament in Bragga's final year.
                    <br />
                    <br />
                    The Thresher spoke to several members of the &lsquo;03 team, as well as coaches, staff and fans. The result is a detailed picture of the years leading up to &lsquo;03, the Owls&rsquo; fairytale season and how the program unraveled in the two decades since.
                    <br />
                    <br />
                    Published April 19, 2023. Design by Fadil Eledath.
                </p>
            </div>
            <div id='animation-container'>
                <div id='batter-section'>
                    <div id='batter-animation' style={{
                        backgroundImage: `url(${starBg})`
                    }}>
                        <div id='batter-row-1'>
                            <span className={batter1Class}>
                                <h3>In 1992, something sparked in the Rice Owls baseball team. Things seemed to click for the first time.</h3>
                            </span>
                            <span className={batter2Class}>
                                <h3>With a 30-game win streak, the Rice Owls were soaring.</h3>
                            </span>
                        </div>
                        <div id='batter-row-2'>
                            <div id='batter-padding'>
                                <div id='batter' style={{
                                    backgroundImage: `url(${frames})`,
                                    backgroundPosition: `${baseballPos}vh`
                                }}></div>
                            </div>
                            <div id='baseball' style={{
                                backgroundImage: `url(${baseball})`,
                                left: baseballXMovement
                            }}></div>
                        </div>
                    </div>
                </div>
                <div id='mckinsey-section' style={{
                    backgroundImage: `url(${starBg})`
                }}>
                    <div id='mckinsey-sub'>
                        <div className="dramatic-text">
                            <span>
                                <h3>After winning the College World Series, Rice had a decision to make. What it chose would affect its legacy.</h3>
                            </span>
                        </div>
                        <div id='baseball-spinning' style={{
                            backgroundImage: `url(${baseball})`,
                            transform: `rotate(${batterVh}deg)`
                        }}></div>
                    </div>
                </div>
                <div id='field-section'>
                    <div id='field-sub'>
                        <div id='field-row-1'>
                            <span href="#" className={field1Class}>
                                <h3>Wayne Graham's team were champions, but Rice wanted a change.</h3>
                            </span>
                            <span href="#" className={field2Class}>
                                <h3>Rice has yet to recreate its magical 2003 season.</h3>
                            </span>
                        </div>
                        <div id="field-svg" style={{
                            filter: `grayscale(${fieldGrayscale}%) invert(${fieldHueDeg}%)`
                        }}></div>
                        <ReactVivus
                            id="field-svg"
                            option={{
                                file: stadium,
                                animTimingFunction: 'EASE',
                                type: 'delayed',
                                onReady: console.log
                            }}
                            callback={console.log}
                        />
                    </div>
                </div>
                <div id='hall-section'>
                    <div id='hall-sub'>
                        <h2 id='hall-text'>Meet the 2003 team</h2>
                        <div id='shelf-contents'>
                            <ReactVivus
                                id="trophy"
                                option={{
                                    file: trophy,
                                    animTimingFunction: 'EASE',
                                    type: 'delayed',
                                    onReady: console.log
                                }}
                                callback={console.log}
                            />
                            {featureList.map((feature, idx) => {
                                return (
                                <a href={feature.link} key={idx} className='pic-title-combo'>
                                    <div className='frame-title'>
                                        <div className='frame-article'>{feature.title}</div>
                                        <div className='frame-author'>{feature.attr}</div>
                                    </div>
                                    <div className='framed-pic' style={{
                                        height: feature.height,
                                        width: feature.width
                                    }}>
                                        <img src={feature.img} />
                                    </div>
                                </a>
                                )
                            })}
                            <br />
                            <div id='shelf-bottom' style={{
                                width: windowData.shelfScrollable + windowData.width
                            }}>
                                <div id='shelf-surface' style={{
                                    backgroundImage: `url(${wood})`
                                }}></div>
                                <div id='shelf-face' style={{
                                    backgroundImage: `url(${wood})`
                                }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 