import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Janish reflects on his unexpectedly long tenure with Rice";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Janish reflects on his unexpectedly long tenure with Rice</h1>

                <div className="author-date">
                    <p id="author">Daniel Schrager</p>
                    <p id="date" className="gray">Sports Editor  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/framed/janish.jpg")} />
                </div>
                <p className="caption gray">Janish makes a throw from shortstop during his time at Rice. He later returned to coach. Courtesy Rice Athletics.</p>

                <p>A lot has changed for Paul Janish in the past 20 years, but just by looking at him on a given weekend afternoon in the spring, you wouldn&rsquo;t be able to tell. Rice&rsquo;s associate head baseball coach mans the third-base coaches&rsquo; box, some 45 feet away from where he lined up for the Owls two decades ago, wearing the same number-11 jersey. But when he wrapped up his nine-year MLB career in 2017, the 2003 national champion said he had no plans to spend the next decade at his alma mater.</p>
<p>&ldquo;I had the full intention of going [to] a position [I had] set up with some private equity guys that do real estate development,&rdquo; Janish said. &ldquo;[Former head] coach [Wayne] Graham asked me to come back and help coach, and I had to finish my degree &hellip; I was totally expecting it to be a one-year deal &mdash; help out the team, finish school and move on with the rest of my life.&rdquo;</p>
<p>Then Rice let Graham walk at the end of his contract, and his replacement, Matt Bragga, asked Janish to stay.</p>
<p>&ldquo;You just felt like there was a lot of convenience,&rdquo; Janish said. &ldquo;I&rsquo;m from Houston, I obviously have a tremendous amount of vested interest in the university. If I had to do it all over again, would I do the same thing? I think so.&rdquo;</p>
<p>Five years and another coaching change later, Janish still spends his days in the Reckling Park dugout. While he didn&rsquo;t initially plan on coaching, he&rsquo;s taken to the role of mentoring his players.</p>
<p>&ldquo;They have a lot of things going on, whether it&rsquo;s school, girlfriends, family, whatever the case is,&rdquo; Janish said. &ldquo;There&rsquo;s a mentor side of it that I appreciate about the college level &hellip; While they irritate you, they also keep you younger.&rdquo;</p>
<p>Janish forgives his players, though &mdash; he said he and his teammates irritated Graham plenty of times during their playing careers.&nbsp;</p>
<p>&ldquo;[On a team trip], six or seven of us rented mopeds and we&rsquo;re riding the mopeds around and we knew, technically speaking, we weren&rsquo;t supposed to be on them,&rdquo; Janish said. &ldquo;Sure enough, we saw Coach Graham&rsquo;s wife, while six of us [were] riding the mopeds around Hawaii.&rdquo;</p>
<p>Janish said all irritation was forgiven when the Owls performed on the field.</p>
<p>&ldquo;We swept them,&rdquo; Janish added, about that same Hawaii trip. &ldquo;We didn&rsquo;t really get in trouble.&rdquo;</p>
<p>Janish matured by the time he made the majors though, lasting nine years across stints with Cincinnati Reds, Atlanta Braves and Baltimore Orioles largely due to what he brought to the clubhouse.</p>
<p>&ldquo;I wasn&rsquo;t really the most talented guy on any team I&rsquo;ve ever played on,&rdquo; Janish said. &ldquo;I think in terms of [being] a teammate guy [in the] clubhouse, I have what I would call some intangibles.&rdquo;</p>
<p>Janish was a part-time starter with the Reds, before settling into a backup role later in his career. Across nearly a decade, Janish said few moments compared to his first ever MLB game.</p>
<p>&ldquo;First day I was in the big leagues, I got put into a game,&rdquo; Janish said. &ldquo;We were up by six runs. The other team scores, ties the game, we go into extra innings. My first major league hit was a walk-off hit.&rdquo;</p>
<p>When he&rsquo;s not coaching, Janish and his wife, also a Rice alum, spend their time raising their three children. According to Janish, Rice has a way of drawing its alumni close even after their time on campus.</p>
<p>&ldquo;The one thing relative to college sports right now, which is so goofy with [name, image, and licensing rules] and [the] transfer portal, [is] there's no loyalty to anything,&rdquo; Janish said. &ldquo;Those aren't super valuable character traits, right? The one thing I can say [to recruits] is, look, if I bring you to Rice to play baseball, I hope we can make you good and you get to play professionally &hellip; But worst case scenario, Rice is probably going to change your life.&rdquo;</p>
<p>Still, After all these years on South Main, Janish said his continued involvement with Rice is a product of chance as much as anything else.</p>
<p>&ldquo;I don&rsquo;t know why we&rsquo;re still here,&rdquo; Janish said. &ldquo;But I&rsquo;m glad that we are.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
