import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "FGLI student leaders tackle the hidden curriculum";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">FGLI student leaders tackle the hidden curriculum</h1>

                <div className="author-date">
                    <p id="author">By Sanjana Jain and Artie Throop</p>
                    <p id="date" className="gray">For the Thresher  |  Jan. 11, 2023</p>
                </div>


                <p>Gabby Franklin was swamped.</p>
<p>&ldquo;I had been taking two seventeen-hour semesters, and then I was also doing the senatorship, and I was also doing a few other clubs and just trying to do what I could while I was here, and so I was always exhausted,&rdquo; Franklin, now the Student Association president, said. &ldquo;The only shifts I could work were early in the morning, but I could only go to bed really late at night. Catching office hours is a whole different train when you also have hourly commitments that you can't move.&rdquo;</p>
<p>Franklin, a Brown College junior, is one of many first-generation low income students who have struggled to balance their many commitments, from courses and social activities to self-advocacy and finding income.</p>
<div className='news-center-image'>
                    <img className='news-image' src={require("../../media/studentworkers/fli cliff_ndidi nwosu.png")} />
                </div>

<p><a href="https://financialaid.rice.edu/rice-investment" target="_blank" rel="noopener">The Rice Investment</a> &mdash;&nbsp;which provides need-based grants to low-income students &mdash; helps students from underserved communities attend Rice. But some students say it is not enough.</p>
<p>&ldquo;Once you get to Rice, even if the Rice Investment can open the door to low-income students across the country, sometimes the other side of that door is a cliff,&rdquo; Kirsty Leech, the Wiess College president and a first-generation student, said.</p>
<p>According to Taylor Breshears, the associate director of Student Success Initiatives, FGLI students are more likely to work and to have commitments off campus.</p>
<p>&ldquo;Rice demands a level of academic rigor that is very time demanding,&rdquo; Breshears said. &ldquo;A truth for [FGLI] students is that they generally have other time obligations that take away time for involvement and student engagement. This isn&rsquo;t the student&rsquo;s fault, it&rsquo;s the systemic issues within higher education.&rdquo;</p>
<p>At times, working during the school year is not practical for FGLI students such as Franklin, who sees working during the summer as a better opportunity.&nbsp;</p>
<p>&ldquo;Over the summer, I've been interning because &hellip; computer science internships pay so well, and so I was able to stock up a serious amount,&rdquo; Franklin said.</p>
<p>In addition to working two other jobs, Jazmine Castillo, the QuestBridge president at Rice, would sell her blood plasma to make additional income.&nbsp;</p>
<p>&ldquo;A lot of the time, I'll bring my laptop and just do my homework one-handed while in the chair having my blood drawn,&rdquo; Castillo, a McMurtry College senior, said. &ldquo;Technically, with plasma, they say you're donating your body, you're selling your time.&rdquo;</p>
<p>The &ldquo;hidden curriculum&rdquo; refers to all the implicit things many students in academia have learned, such as taking advantage of office hours, navigating relationships with faculty, finding resources and understanding cultural norms. This includes navigating finances in the social sphere &mdash; whether to eat out, host an event or go on a trip with friends.</p>
<p>QuestBridge scholars receive a stipend in addition to their academic scholarships, but the expenses still add up.</p>
<p>&ldquo;I&rsquo;ve tried to live like a Rice student for the past couple of years, and now I&rsquo;m in debt,&rdquo; Castillo said. &ldquo;I know that that's not the right thing to do, but I also didn't know what else to do.&rdquo;</p>
<p>Once she graduates, Castillo said she will have to pay the debts she has accumulated at Rice since she used her stipend to pay bills.</p>
<p>&ldquo;[There&rsquo;s] no way for me to get out of here without debt whilst also being socially competent at Rice,&rdquo; Castillo said.</p>
<p>Leech said she takes an intersectional approach to leadership and prioritizes supporting under-resourced communities in her work as president. She explained that her identity as a queer and FGLI woman allows her to see others on the margins.</p>
<p>&ldquo;[It] has allowed for me to better understand the way higher education institutions can serve to marginalize diverse communities on campus, and the extent to which the under-resourcing of those communities can translate into pretty significant individual disparities, such as in academic performance or campus engagement,&rdquo; Leech said. &ldquo;I understand and can empathize with what it is when the Rice experience is not necessarily easily engaged for people of different identities.&rdquo;</p>

                <p id="author">Illustration by Ndidi Nwosu. Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
