import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import Ion from "../../../media/ion/ion-features/ion.jpg"

const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Develop?", "Invest?", "Grow?", "Marginalize?", "Gentrify?", "Unify?"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300- Math.random() *100);
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        },delta)

        return () => {clearInterval(ticker)}
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1): fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting){
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);  
        } else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }

    return (
        <section className='banner' id='home'>
            <Container>
                <Row className='align-items-center'>
                    <Col xs={12} md={12} xl={7}>
                        <span className='tagline'>The Ion</span>
                        <h1>{`How does it affect residents?` }<br></br>‎<span className='wrap'>{text}</span></h1>
                        
                    </Col>
                    <Col xs = {12} md = {6} xl = {5}>
                        <img src={Ion} alt={{Ion}} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}




export default Banner;