import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Bragga brought 'positive attitude,' priority shifts to his disappointing tenure at Rice";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Bragga brought 'positive attitude,' priority shifts to his disappointing tenure at Rice</h1>

                <div className="author-date">
                    <p id="author">Murtaza Kazmi</p>
                    <p id="date" className="gray">Thresher Staff  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/story 6 Thresher Archives.jpg")} />
                </div>
                <p className="caption gray">Matt Bragga coaches his team during his tenure at Rice. Bragga was let go after three disappointing seasons. Thresher archives.</p>

                <p>When athletic director Joe Karlgaard introduced his new head coach in June 2018, he had nothing but praise.</p>
<p>&ldquo;Matt Bragga is the right person to carry on the terrific legacy and national prominence of Rice baseball,&rdquo; Karlgaard said <a href="https://riceowls.com/news/2018/6/15/Matt_Bragga_Named_21st_Baseball_Coach.aspx" target="_blank" rel="noopener">in his announcement</a>.</p>
<p>Three seasons later, Bragga was relieved of his duties after failing to bring the Owls to the conference tournament for the first time since 1993.</p>
<p>The Thresher reached out to Bragga, now the head coach at Tennessee Tech University, directly and through Mike Lehman, TTU&rsquo;s director of sports information. Neither Bragga nor Lehman responded to multiple requests for an interview.</p>
<p>Bragga amassed a 51-76-1 record in his three seasons at Rice. His difficulties may be attributed to the methods that had brought Bragga success in past coaching stints not carrying over to Rice, according to Paul Janish, a former Rice baseball star who has been an assistant coach at Rice since 2017.&nbsp;</p>
<p>According to Karlgaard, Wayne Graham &mdash; the legendary Rice baseball coach &mdash; was informed a year prior to the end of his contract that the team would be moving in a new direction. Graham had been Rice&rsquo;s head coach since 1992.</p>
<p>Soon after the conclusion of the 2018 season, Karlgaard began the search for Graham&rsquo;s replacement. Previous experience was high among his priorities.&nbsp;</p>
<p>&ldquo;I remember thinking that, when you&rsquo;re trying to replace the greatest head coach in Rice University history, you have to have a different mindset going into it,&rdquo; Karlgaard said. &ldquo;I was interested in seeing if we could find somebody who had head coaching experience and had a track record of high level success relative to their institution as a head coach.&rdquo;</p>
<p>Bragga had been the head coach at Tennessee Tech University for 15 years by 2018. His tenure at TTU was successful &mdash; he won six conference championships in his last ten years. In his final year, Bragga&rsquo;s team finished one win away from the College World Series after losing a hard-fought series to the University of Texas at Austin in the Super Regionals.&nbsp;</p>
<p>Bragga impressed Karlgaard with his ability to elevate less talented teams through a positive attitude, Karlgaard said.&nbsp;</p>
<p>&ldquo;We were seeing what [Bragga] was doing with a resource base that was pretty limited [at TTU],&rdquo; Karlgaard said. &ldquo;[Bragga], as I remember throughout the interview process and during my interactions with him here, is just a relentlessly positive person, and that seemed to be a very attractive element.&rdquo;</p>
<p>Although Bragga did not have experience at a small, private, academically strong institution like Rice, Karlgaard said that those concerns did not phase him given Rice&rsquo;s history with out-of-house candidates.&nbsp;</p>
<p>&ldquo;I know coach Graham grew up in Houston and grew up coming to Rice games, but he came from San Jacinto Junior College,&rdquo; Karlgaard said. &ldquo;[He] had had a high level of success at an institution that&rsquo;s not a lot like Rice, so we&rsquo;d had success with that model before.&rdquo;</p>
<p>To Janish, Bragga showed enthusiasm while entering a new environment.&nbsp;</p>
<p>&ldquo;Bragga was excited about the job,&rdquo; Janish said. &ldquo;The city of Houston and Rice University was not probably super familiar for him at the time, [with] the dynamics [of] a larger metropolitan area and of small academic private universities, so he had some pertinent questions about some of those things, but he was an energetic guy.&rdquo;</p>
<p>Bragga implemented an analytics-based approach to the sport, according to Joshua Larzabal, a pitcher for the Rice Baseball team between 2017 and 2021.</p>
<p>&ldquo;[Bragga] was a numbers coach,&rdquo; Larzabal explained. &ldquo;All of his practices were competition and everything was scored. Although, there were times where the scoring and leaderboards did not correlate to playing time.&rdquo;</p>
<p>Under Bragga, the team adopted an offensive-minded strategy, thus being referred to affectionately as the &ldquo;Slugging Owls.&rdquo; In Bragga&rsquo;s first year, the Owls had their highest home run rate since 2010.&nbsp;</p>
<p>This offensive philosophy was in contrast to Graham&rsquo;s focus on pitching and defense during his tenure. Still, Karlgaard said that both approaches had merit.</p>
<p>Bragga&rsquo;s first year at the helm saw the Owls finish 26-33, with a 14-16 conference record. The season included wins over Texas Christian University and Baylor University, taking the Silver Glove Series against the University of Houston, and finishing 2-2 in the Conference USA tournament.&nbsp;</p>
<p>Janish, who also played for Rice during its 2003 College World Series championship run, said Bragga and Graham also had different styles of communicating.&nbsp;</p>
<p>&ldquo;Coach Graham was historically a pretty firm guy &hellip; There was an expectation there,&rdquo; Janish said. &ldquo;I think from a communications standpoint, Bragga had some work to do because he was new [and] had come from a different type of place.&rdquo;</p>
<p>A difficult start to the 2020 season and the pausing of play due to the COVID-19 pandemic meant wins were hard to come by for Bragga. When the season was canceled, the Owls&rsquo; record sat at 2-14, though they had faced the <a href="https://riceowls.com/sports/baseball/roster/coaches/matt-bragga/497" target="_blank" rel="noopener">eleventh most difficult</a> non-conference schedule in the country, according to Rice.</p>
<p>Larzabal said that Bragga never lost the locker room, but that his style didn&rsquo;t suit the roster he inherited from Graham.&nbsp;</p>
<p>&ldquo;[Bragga&rsquo;s] ways at Tennessee Tech were not mixing with the type of players he had at Rice,&rdquo; Larzabal said &ldquo;It showed all around the program. We didn&rsquo;t maximize our resources like we should have.&rdquo;</p>
<p>Janish said that Bragga had some difficulty adjusting his style to the unique culture that Rice&rsquo;s academic stress and quirky nature presented.&nbsp;</p>
<p>&ldquo;It's [tough] for me to say that [Bragga&rsquo;s adjustment period] ever completely resolved. He was here for three years, which is a short period of time in the landscape of college sports,&rdquo; Janish said. &ldquo;That time frame is so hard to completely change the system. I do think that there was some buy-in, while he was here, but it's hard for me to tell you that those gaps were [fully] bridged.&rdquo;</p>
<p>In Bragga&rsquo;s third season, Rice had a 23-29-1 record, 11-20-1 in-conference and failed to reach the conference tournament for the first time since 1993. Eventually, Karlgaard decided the results were not sufficient to keep Bragga in the building.&nbsp;</p>
<p>&ldquo;I became convinced at some point during the 2021 season that the program wasn't heading in the right direction,&rdquo; Karlgaard said. &ldquo;I don't think we've been heading in the right direction for a few years and we needed to be in that process of moving forward, and so ultimately that's why we moved on from [Bragga].&rdquo;</p>
<p>Karglaard said that he learned several lessons when hiring Cruz.&nbsp;</p>
<p>&ldquo;I loosened my aperture a little bit the second time around,&rdquo; Karlgaard explained. &ldquo;We were looking for head coaching experience the first time around, but the second time around it was [about] having high-level understanding [of] what makes this place unique.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
