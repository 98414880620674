import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "Philip Humber revisits a career of ups and downs";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">Philip Humber revisits a career of ups and downs</h1>

                <div className="author-date">
                    <p id="author">Landry Wood</p>
                    <p id="date" className="gray">Thresher Staff  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/framed/humber.jpg")} />
                </div>
                <p className="caption gray">Philip Humber threw a complete game to seal Rice’s 2003 College World Series victory. Humber later threw a perfect game with the Chicago White Sox before struggling and being released. Courtesy Rice Athletics.</p>

                <p>At 9:01 p.m. on June 23, 2003, Philip Humber&rsquo;s teammates tackled him to the ground. He had just thrown a complete game, allowing two runs on five hits, against Stanford University. At the moment the last batter grounded out, Humber was awarded his eleventh win of the season and Rice became the national champion of a team sport for the first time in school history. So, at the base of the dogpile, Humber lay smiling.</p>
<p>&ldquo;The national championship,&rdquo; Humber said, &ldquo;is the thing I&rsquo;m most thankful for that happened during my baseball life.&rdquo;</p>
<p>Humber grew up in Carthage, Texas and elected to play ball at Rice over signing with the Yankees out of high school. He saw success under Owls head coach Wayne Graham, stepping into the starting rotation as a freshman in 2002. That year, the team reached, but exited early from, the College World Series, losing their first two games and being eliminated before Humber could have his first Omaha start.&nbsp;</p>
<p>The next season, the Owls rode a school-record 30-game win streak through the middle of the season to make it back. Throughout, the Owls&rsquo; &ldquo;Big Three&rdquo; starters &mdash; Jeff Niemann, Wade Townsend and Humber, all sophomores &mdash; excelled, in Humber&rsquo;s eyes benefitting from the team&rsquo;s solidity on defense.</p>
<p>&ldquo;[The streak] is mind boggling to think about now,&rdquo; Humber said. &ldquo;Being able to win 30 consecutive games, at any level, is hard to imagine because things that don&rsquo;t go your way just happen. But I think that just showed how solid we were, since in baseball, pitching and defense is really what wins you most of the games.&rdquo;</p>
<p>After coming home as a champion that June, Humber prepared for another season with many of the key components which brought success in 2003. Things went well for Humber in 2004, as he improved his ERA to 2.27 and set the Rice single-game strikeout record, ringing up 17 University of Hawaii hitters. However, according to Humber, a pattern of ups-and-downs began to appear during that year&rsquo;s NCAA tournament that would come to characterize his career.</p>
<p>&ldquo;The last game that I pitched [that year] was the last game of our season,&rdquo; Humber said. &ldquo;I came in relief and ended up giving up, as the last pitch I threw in college, a grand slam. You went from my last game in 2003, where you can&rsquo;t get any better than that &hellip; to that incredibly low moment.&rdquo;</p>
<p>Humber entered the Major League Baseball draft the next day and was <a href="https://texashistory.unt.edu/ark:/67531/metapth398475/m1/21/?q=Humber" target="_blank" rel="noopener">selected</a> by the New York Mets as the third overall pick, immediately behind MLB star Justin Verlander and before Niemann. Humber&rsquo;s career, however, remained unstable.</p>
<p>&ldquo;[In 2005] I had <a href="https://www.hopkinsmedicine.org/health/treatment-tests-and-therapies/tommy-john-surgery-ulnar-collateral-ligament-reconstruction" target="_blank" rel="noopener">Tommy John surgery</a>, which probably did impact the course of my career in a lot of ways,&rdquo; Humber explained. &ldquo;I don&rsquo;t think I ever got back to having that same kind of ability, from an arm strength standpoint &hellip;&nbsp; For whatever reason, I never was just steady, consistent, one way or the other.&rdquo;</p>
<p>Humber's teammates tackled him to the ground once again on April 21, 2012, when he pitched the twenty-first perfect game in MLB history. But the &ldquo;down&rdquo; soon followed, as he allowed 20 runs across his next three starts and was placed on waivers by the Chicago White Sox at the season&rsquo;s end.</p>
<p>Humber retired in 2016, having bounced between teams since 2012, and moved back to his homeland of east Texas. After finishing his coursework at Rice in 2017, he has taken up a residence in Tyler and a career in commercial real estate development.</p>
<p>&ldquo;When I got done with baseball, I really needed to get away from it,&rdquo; Humber said. &ldquo;The company that I had interned with offered me a full-time position, so I took that and went there to work &hellip; that's kind of a big accomplishment for me in the fact that I feel like, okay, I had a baseball career, and now I'm to the point where I feel like I'm having a another really good career in a totally different field.&rdquo;</p>
<p>Humber said he still maintains a connection to baseball by coaching his son&rsquo;s travel team.</p>
<p>&ldquo;My son is 10 years old, and he loves all sports, anything with a ball,&rdquo; Humber said. &ldquo;We started a little team with his buddies &hellip; A lot of things that I used to hate hearing coach Graham say, over and over, now, I'm the one saying that.&rdquo;</p>
<p>When Humber looks back on his baseball career, he said he feels particular fondness and appreciation for his years as an Owl.</p>
<p>&ldquo;At the end of any season only one team is happy,&rdquo; Humber said. &ldquo;And that's the team that wins the last game. We were fortunate enough to be that team.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
