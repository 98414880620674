import React from 'react'
import '../../stylings/abortionNewsStyles.css';
import 'bootstrap/dist/css/bootstrap.css'; //Bootstrap

export default function InnovationPage() {
    React.useEffect(() => {
        document.title = "'I have the ability to control time': Wade Townsend talks baseball, poker, his spiritual awakening";
    }, []);

    return (
        <div>

            <div className="main-abortion">
                <h1 className="abortion-news-text-center">'I have the ability to control time': Wade Townsend talks baseball, poker, his spiritual awakening</h1>

                <div className="author-date">
                    <p id="author">Cadan Hanson</p>
                    <p id="date" className="gray">Senior Writer  |  April 19, 2023</p>
                </div>

                <div className='news-center-image'>
                    <img className='news-image' src={require("../../media/rice-baseball/framed/townsend.jpg")} />
                </div>
                <p className="caption gray">Wade Townsend pitching for the Owls. Townsend gave up baseball to become a professional poker player and now lives in the "middle of nowhere."</p>

                <p><em>Editor&rsquo;s note: This article contains references to jokes about suicide.&nbsp;</em></p>
<p>You won&rsquo;t find former Rice pitcher Wade Townsend in a strip club because, as he says, there&rsquo;s not enough competition in it for him.</p>
<p>&ldquo;Some people are strip club guys, but that&rsquo;s not me,&rdquo; Townsend said. &ldquo;I&rsquo;m in for a competition. I&rsquo;m not [here] for just an exhibition.&rdquo;</p>
<p>Here are some places you might find him: Outside, where he likes to pee, according to his Twitter bio. In the woods, where he likes to meditate and shoot hogs. In his head, where he thinks he might be God, or maybe just Wade. (In his defense, he also thinks everyone else is God, or maybe just themselves.)</p>
<p>In old photos and on old Thresher sports pages, you&rsquo;ll see him pitching for Rice&rsquo;s 2003 national championship-winning baseball team. You&rsquo;ll also follow his struggles and injuries in the minor leagues, bussing around the country hoping to make it to the majors while undergoing four major arm surgeries.</p>
<p>A few years ago, you would&rsquo;ve found him hanging out with cartel members in Cabo, after his professional poker career led him to Mexico. And today on the internet, you&rsquo;ll find Townsend playing online poker with cryptocurrency and tweeting about sex, suicide, grocery shopping, politics, transgender people, beer, Jews, Jesus (no relation) and occasionally Rice baseball.</p>
{/* <p style={{textAlign: 'center'}}>&#11835;</p> */}
<p>Townsend, bald except for an occasionally braided mohawk, considers himself a game theory expert, dating back to his days growing up in Dripping Springs, Texas, where he played Scrabble, Yahtzee and gin.</p>
<p>&ldquo;Me existing and eating is a product of me winning those games,&rdquo; Townsend said.&nbsp;</p>
<p>There is an old clich&eacute; that &ldquo;sports is just a game,&rdquo; and Townsend has tried to put that proverb to the test.</p>
<p>&ldquo;[Baseball] is just playing game theory, which I&rsquo;m a master at,&rdquo; Townsend said. &ldquo;I see holes and how you can beat games. I&rsquo;ve grown up doing that, so that was just all natural stuff for me.&rdquo;</p>
<p>The mental edge that Townsend developed was employed on the mound, where he posted a career 23-2 record and 2.00 ERA for the Owls under former head coach Wayne Graham, helping them win the 2003 national title.</p>
<p>&ldquo;I was always known as being kind of insane on the mound,&rdquo; Townsend said. &ldquo;Kind of crazy, yelling at the other team, starting fights, throwing at people. But I never yelled at umpires ever, and you know why? Because they&rsquo;re human beings. Of course, they&rsquo;re gonna screw you if you&rsquo;re an asshole to them. That isn&rsquo;t the way to manipulate people.&rdquo;</p>
<p>These skills helped Townsend become a first-round pick in the 2004 MLB draft by the Baltimore Orioles. The <a href="https://www.baseballamerica.com/players/48263/wade-townsend/" target="_blank" rel="noopener">organization waited months</a> before reaching out to negotiate a contract &mdash; and when they did, the offer was almost half a million dollars below the slot value and not up for negotiation, Townsaid said. Rather than sign, Townsend opted to return to Rice, renouncing his college baseball eligibility and scholarship in the process.&nbsp;</p>
<p>The next year, Townsend was again selected No. 8 overall, this time by the Tampa Bay Devil Rays. Over the following three years, Townsend struggled with injuries, resulting in Tommy John surgery among other operations. While rehabbing, Townsend began to pick up poker to make money during the offseason, before eventually traveling to compete in tournaments around the world.</p>
<p>&ldquo;I started playing poker while I was hurt because I had nothing to do all day,&rdquo; Townsend said. &ldquo;I deposited $20 and then I ran that up until everything I&rsquo;m playing with today.&rdquo;</p>
<p>The same manipulation skills that helped him the field let Townsend clean tables playing Texas Hold &lsquo;Em and &ldquo;Sit And Go,&rdquo; a type of one-on-one poker game that he still enjoys.</p>
<p>&ldquo;I&rsquo;m good at understanding what&rsquo;s in other people&rsquo;s heads,&rdquo; Townsend said. &ldquo;It&rsquo;s about manipulating their emotions, manipulating what they believe about you and then waiting for people to make a huge mistake.&rdquo;</p>
<p>After leaving the Rays, Townsend signed with the Toronto Blue Jays but suffered another injury. He decided to end his six-year professional baseball career and turn to the online casinos, competing professionally across the globe.</p>
<p>&ldquo;Minor League Baseball is so miserable and awful,&rdquo; Townsend said. &ldquo;It&rsquo;s just a terrible, terrible experience. Nobody&rsquo;s trying to win &hellip; and it honestly drove me insane. I realized that I can travel around the world, seduce beautiful women from different countries, experience all these cultures, learn different languages, see cool things and meet friends all over the world.&rdquo;</p>
<p>But then 2011 came. The United States banned online gambling and began to seize bank accounts, which today is known as the <a href="https://www.gamblingsites.com/history/black-friday/" target="_blank" rel="noopener">&ldquo;Black Friday of Poker</a>.&rdquo; To continue his poker career, Townsend decided to pack up and move to Mexico.</p>
<p>&ldquo;When they shut down everything in the U.S., I had to figure out how to get [to Mexico], sign a lease, get bank accounts and do business in Spanish,&rdquo; Townsend said. &ldquo;There was almost a brain drain, as far as the poker world in the U.S. All the best players left.&rdquo;</p>
<p>Although Mexico became a second home to Townsend, he still found it frustrating that the U.S. made a decision that ultimately hurt the future of the sport.</p>
<p>&ldquo;I had to leave this bastion of liberty, America, to go play internet poker down there,&rdquo; Townsend said. &ldquo;The first word of Texas Hold&rsquo; Em is Texas, and you couldn&rsquo;t play in Texas. I have to go to fucking Mexico. Not only that, I was smuggling drugs into Mexico, not the other way around. It was a total conundrum.&rdquo;</p>
<p>While living in Mexico, Townsend had run-ins with cartels in Cabo, which he said didn&rsquo;t fit their stereotypical portrayals.</p>
<p>&ldquo;People don&rsquo;t understand the cartel,&rdquo; Townsend said. &ldquo;There&rsquo;s just cool dudes. The cartels are some of the shrewdest and wisest and most creative people you&rsquo;re going to find. Everybody was there to protect you, to keep you from getting hurt, because the cartels own all the clubs and restaurants. You weren&rsquo;t going to pass out in the street and wake up with your liver gone.&rdquo;</p>
<p>After his days in Mexico, Townsend returned to the U.S. and settled down in the &ldquo;middle of nowhere&rdquo; on a family farm between Austin and Bastrop, TX. The evolution of cryptocurrency created a loophole in the ban on online gambling in the U.S., which meant that Townsend could continue to gamble in the wilderness.</p>
<p>&ldquo;I just enjoy sitting in the woods and having a garden,&rdquo; Townsend said. &ldquo;I shoot hogs and I eat them. It&rsquo;s a great lifestyle.&rdquo;</p>
<p>In addition to hunting, gardening and gambling, Townsend spends his days playing the role of Twitter provocateur. The blatantly offensive tweets often sent from his account are, in his words, intended to provoke conversation. One <a href="https://twitter.com/wadetownsendman/status/1628285467115372544?s=42&amp;t=mUjqvWGHV9Oy-tjGBcq6Vg" target="_blank" rel="noopener">recently-pinned tweet</a> reads, &ldquo;It is sad because when hot chicks commit suicide you can&rsquo;t fuck them anymore.&rdquo;</p>
<p>&ldquo;That&rsquo;s just a true statement, unless you&rsquo;re into necrophilia,&rdquo; Townsend said.</p>
<p>But Townsend said his posts often start meaningful conversations as well.</p>
<p>&ldquo;When you say things that are not controversial, necessarily, they&rsquo;re just harsh &hellip; it starts conversations with people,&ldquo; Townsend said. &ldquo;You finally have somebody they can [tell], &lsquo;Listen, I&rsquo;m offended by your post.&rsquo; And then we start talking about, [and] in the end of the story, they say, &lsquo;I&rsquo;m glad I had somebody to talk to.&rsquo;&rdquo;</p>
<p>A few hours after the Thresher asked Townsend about his offensive tweets, he replaced his previously pinned tweet with a <a href="https://twitter.com/WadeTownsendMan/status/1647786269324238853" target="_blank" rel="noopener">new message</a>: &ldquo;I&rsquo;m sorry for the things I said when I was sober.&rdquo;</p>
<p>Six years ago, Townsend put away the chips for a while to ponder life.</p>
<p>&ldquo;I spent two years in the woods, just thinking,&rdquo; Townsend said. &ldquo;I went all the way into philosophy, religion and spirituality and popped all the way back out. I learned a lot, and now I have the ability to control time, which is related to Einstein&rsquo;s relativity thing. When you&rsquo;re aware of stuff every day, and you&rsquo;re not rushed, you&rsquo;ll find out time goes by way slower.&rdquo;</p>
<p>During his spiritual awakening, Townsend, who learned a lot from his philosophical muse Alan Watts, said eventually discovered who he is.</p>
<p>&ldquo;When you finally recognize who you are, it&rsquo;s the greatest day in your life,&rdquo; Townsend said. &ldquo;I&rsquo;ll give you the secret right? You are God. You put yourself here. You chose to be here. And you know why? Because omniscience is totally boring, there&rsquo;s no surprises.&rdquo;</p>
<p>One person to whom Townsend wouldn&rsquo;t ascribe divine status is Rice athletic director Joe Karlgaard. When Karlgaard made the decision to get rid of Graham, Townsend voiced his disagreement publicly.</p>
<p>&ldquo;You talk about pearls before swine,&rdquo; Townsend said. &ldquo;You get rid of the only guy that&rsquo;s won anything at Rice ever, who basically built and saved the athletic department, and [then-President David Leebron and Karlgaard] treated him like total dirt. They shouldn&rsquo;t have [let his contract expire], and they sure as hell shouldn&rsquo;t have disrespected him like they did.&rdquo;</p>
<p>Karlgaard declined to comment for this article.</p>
<p>Townsend has admired Graham, whom he compared to a Zen master, ever since his days playing for the long-time head coach.</p>
<p>If letting Graham go wasn&rsquo;t bad enough, Townsend added, Karlgaard chose to ignore Graham&rsquo;s suggestion to hire long-time Rice assistant coach Patrick Hallmark, who has since taken the University of Texas at San Antonio baseball team, which had never been ranked, to No. 25 in the country.</p>
<p>&ldquo;Karlgaard fires Graham, which is insane, doesn&rsquo;t listen to him about who to hire, which is insane, and hires Matt Bragga, who shit the bed as bad as you possibly could as a baseball coach,&rdquo; Townsend said. &ldquo;You take a program that&rsquo;s world-renowned for excellence, and you literally turn it into a dumpster fire within three years.&rdquo;</p>
<p>Despite his current disdain for the athletic department, Townsend said that he is still proud of what he and his teammates accomplished at Rice.</p>
<p>&ldquo;We answered JFK&rsquo;s famous question, &lsquo;Why does Rice play Texas?&rsquo;&rdquo; Townsend said. &ldquo;We kicked their ass, that&rsquo;s why.&rdquo;</p>
                
                <p id="author">Design by Prayag Gordy.</p>
            </div>
        </div>
    )
}
